import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { AddToCartComponent } from 'src/app/components/add-to-cart/add-to-cart.component';
import { ArticlesService } from 'src/app/services/articles.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CartService } from 'src/app/services/cart.service';
import SwiperCore, { Zoom, Navigation, Pagination, FreeMode, Thumbs } from "swiper";
import { SwiperComponent } from 'swiper/angular';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { AppSettings } from 'src/app/app-settings';
SwiperCore.use([Zoom, Navigation, Pagination, FreeMode, Thumbs]);


@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {


  // @ViewChild('swiperProductThumbnails', { static: false }) swiperProductThumbnails?: SwiperComponent;
  // @ViewChild('swiperProductCover', { static: false }) swiperProductCover?: SwiperComponent;

  public familysBreadcrums: string[] = ['Tornillos', 'Tornillos para cubiertas y fachadas'];
  public relatedProducts: any[];
  product: any;
  imgPath = AppSettings.API_ASSETS;
  imgPathAPI = AppSettings.API_ASSETS;

  tableHeaders: string[] = [];
  tableFields: string[] = [];
  tableData: any[] = [];
  tableTypes: any[] = [];

  showTables: any[] = [];

  listProducts: any[] = [];
  loadedProducts: boolean = true;
  envaseA: any;
  envaseB: any;
  precio: any;
  precioString: any;

  subfamilyCode: string = '';
  subfamilyDescription: string = '';
  subfamilyId: string = '';

  isLogged: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private articlesService: ArticlesService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private toastEvokeService: ToastEvokeService) {
  }

  ngOnInit(): void {
    this.authenticationService.check().subscribe(data => {
      this.isLogged = true;
      this.getProductsLoged();
    }, error => {
      this.isLogged = false;
      this.getProductsGeneral();
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.subfamilyCode = this.router.url.split('subfamilyCode=')[1].split('&')[0];
      this.subfamilyDescription = params.subfamilyDescription;
    });

    this.activatedRoute.params.subscribe(response => {
      this.subfamilyId = response.idSubfamily
      this.imgPath = this.imgPath + 'productos/' + this.subfamilyId + '.jpg';
    });
    //this.resetCursor();
    //this.product = { id: 1, name: 'AMN', description: 'Anclaje AMN macho, para cargas altas, zincado', img: 'product_example.png' }
    //this.relatedProducts = Array(6).fill(this.product);
  }

  getProductsGeneral() {
    this.loadedProducts = true;
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: false,
      bdColor: "rgba(0, 0, 0, 0)",
    });

    if (this.subfamilyCode) {
      this.articlesService.getArticlesBySubfamily(this.subfamilyCode).subscribe((data) => {
        for (let index = 0; index < data.types.length; index++) {
          let table = { tableFields: [], tableHeaders: [], tableType: {}, listProducts: [] };
          table.tableFields = data.Campos;
          table.tableHeaders = data.Headers;
          table.tableType = data.types[index];
          // this.tableFields = data.Campos;
          // this.tableHeaders = data.Headers;
          // this.tableTypes = data.types;

          const total_lines = data.types[index].data.length;
          let current_line = 0;
          data.types[index].data.forEach(key => {

            if (key.hasOwnProperty('Euro100')) {
              if (current_line == 0) {
                this.precio = this.formatNumber(key.Euro100.replace(',', '.'), 3);
                this.precioString = "/100 uds."
              }
              key.Euro100 = this.formatNumber(key.Euro100.replace(',', '.'), 3);
            }

            if (key.hasOwnProperty('EuroUnidad')) {
              if (current_line == 0) {
                this.precioString = "/Ud."
                this.precio = this.formatNumber(key.EuroUnidad.replace(',', '.'), 3);
              }
              key.EuroUnidad = this.formatNumber(key.EuroUnidad.replace(',', '.'), 3);

            }

            if (key.hasOwnProperty('EuroKit')) {
              if (current_line == 0) {
                this.precioString = "/Kit"
                this.precio = this.formatNumber(key.EuroKit.replace(',', '.'), 3);
              }
              key.EuroUnidad = this.formatNumber(key.EuroKit.replace(',', '.'), 3);

            }

            table.listProducts.push(key);
            // this.listProducts.push(key);

            current_line++;
            if (total_lines === current_line) {
              this.spinner.hide();
              this.loadedProducts = false;

              if (table.listProducts[0]?.EnvaseA) {
                this.envaseA = table.listProducts[0]?.EnvaseA
                // this.envaseA = this.listProducts[0]?.EnvaseA
              }
              if (table.listProducts[0]?.EnvaseB) {
                this.envaseB = table.listProducts[0]?.EnvaseB
                // this.envaseB = this.listProducts[0]?.EnvaseB
              }
            }
          })

          this.showTables.push(table);
        }
      })
    }
  }

  getProductsLoged() {
    this.loadedProducts = true;
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: false,
      bdColor: "rgba(0, 0, 0, 0)",
    });

    if (this.subfamilyCode) {
      this.articlesService.getArticlesBySubfamily(this.subfamilyCode).subscribe((data) => {
        if (data) {
          for (let index = 0; index < data.types.length; index++) {
            let table = { tableFields: [], tableHeaders: [], tableType: {}, listProducts: [] };
            table.tableFields = data.Campos;
            table.tableHeaders = data.Headers;
            table.tableType = data.types[index];
            // this.tableFields = data.Campos;
            // this.tableHeaders = data.Headers;
            // this.tableTypes = data.types;

            const total_lines = data.types[index].data.length;
            let current_line = 0;
            data.types[index].data.forEach(key => {

              this.cartService.getPriceSKU({ "sku": key.codigo, "cantidad": 1 }).subscribe(price_data => {
                if (key.hasOwnProperty('Euro100')) {
                  if (current_line == 0) {
                    this.precio = this.formatNumber(price_data.Price, 3);
                    this.precioString = "/100 uds."
                  }
                  key.Euro100 = this.formatNumber(price_data.Price, 3);
                }
                if (key.hasOwnProperty('EuroUnidad')) {
                  if (current_line == 0) {
                    this.precioString = "/Ud."
                    this.precio = this.formatNumber(price_data.Price, 3);
                  }
                  key.EuroUnidad = this.formatNumber(price_data.Price, 3);
                }
                if (key.hasOwnProperty('EuroKit')) {
                  if (current_line == 0) {
                    this.precioString = "/Kit"
                    this.precio = this.formatNumber(price_data.Price, 3);
                  }
                  key.EuroKit = this.formatNumber(price_data.Price, 3);
                }

                table.listProducts.push(key);

                current_line++;
                if (total_lines === current_line) {
                  this.spinner.hide();
                  this.loadedProducts = false;

                  if (table.listProducts[0]?.EnvaseA) {
                    this.envaseA = table.listProducts[0]?.EnvaseA
                    // this.envaseA = this.listProducts[0]?.EnvaseA
                  }
                  if (table.listProducts[0]?.EnvaseB) {
                    this.envaseB = table.listProducts[0]?.EnvaseB
                    // this.envaseB = this.listProducts[0]?.EnvaseB
                  }
                }
              })

              // this.listProducts.push(key);

            })

            this.showTables.push(table);
          }

        }
      }
      )
    }
  }

  // nextSlide() {
  //   this.swiperProductThumbnails?.swiperRef.slideNext(500);
  //   this.swiperProductCover?.swiperRef.slideNext(500);
  // }
  // previousSlide() {
  //   this.swiperProductThumbnails?.swiperRef.slidePrev(500);
  //   this.swiperProductCover?.swiperRef.slidePrev(500);
  // }

  resetCursor() {
    const cursor = document.getElementById('cursor');
    cursor.style.background = '#FFFFFF'
    cursor.style.border = 'none'
    cursor.style.scale = "1"
    cursor.style.mixBlendMode = "difference"
    cursor.style.width = '20px'
    cursor.style.height = '20px'
    var styleElem = document.head.appendChild(document.createElement("style"));
    styleElem.innerHTML = "#cursor:before {content: none;}";
  }

  addToCart(article: any) {
    article.title = 'Añadir al carrito';
    const dialogRef = this.dialog.open(AddToCartComponent, {
      panelClass: 'generic-dialog',
      data: { article: article, headers: this.tableHeaders },
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        console.log(data)
        if (data) {
          this.toastEvokeService.success('Carrito', data).subscribe();
        }
      }
    );
  }

  formatNumber(floatValue = 1, decimals = 3, multiplier = 1) {
    let floatMultiplied = floatValue * multiplier;
    let stringFloat = floatMultiplied + "";
    let arraySplitFloat = stringFloat.split(".");
    let decimalsValue = "0";
    if (arraySplitFloat.length > 1) {
      decimalsValue = arraySplitFloat[1].slice(0, decimals);
    }
    let integerValue = arraySplitFloat[0];
    let arrayFullStringValue = [integerValue, decimalsValue];
    let FullStringValue = arrayFullStringValue.join(".")
    let floatFullValue = parseFloat(FullStringValue) + "";
    let formatFloatFullValue = new Intl.NumberFormat('de-DE', { minimumFractionDigits: decimals, style: 'currency', currency: 'EUR' }).format(Number(floatFullValue));
    return formatFloatFullValue;
  }

}
