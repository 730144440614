<div class="products-categories-wrapper content-wrapper">
  <div class="container p-0 d-flex  justify-content-between ">
    <div class="products-sidebar-wrapper d-flex">
      <app-sidebar-product-filter (changeRoute)="changeRoute($event)"></app-sidebar-product-filter>
    </div>
    <div class="products-panel-wrapper">
      <div class="row align-items-center ">
        <div class="col-7">
          <div *ngIf="!searchActive">
            <!-- <app-breadcrumbs></app-breadcrumbs> -->
          </div>
        </div>
        <div class="col-5">
          <mat-form-field class="w-100 example-full-width">
            <input #inputSearch type="text" matInput placeholder="Buscar en todo el catálogo..."
              [(ngModel)]="querySearch" >
            <mat-icon matPrefix (click)="search()" style="cursor: pointer;" class="my-icon">search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="product-cards-wrapper">
        <div class="row" *ngIf="!searchActive; else searchResults">
          <div class="spinner" *ngIf="!loaded" style="height: 800px;">
            <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple"> </ngx-spinner>
          </div>
          <div class="col-6 col-xl-3 p-3 product-card" *ngFor="let product of productsArray"
            (click)="viewProduct(product)">
            <div class="product-mouse-effect">
              <app-product-card [product]="product"></app-product-card>
            </div>
          </div>
        </div>
        <ng-template #searchResults>
          <div class="spinner" *ngIf="!loaded" style="height: 800px;">
            <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple"> </ngx-spinner>
          </div>
          <div class="row">
            <div class="col-6 col-xl-3 p-3 product-card" *ngFor="let product of productsArray"
              (click)="viewProduct(product)">
              <div class="product-mouse-effect">
                <app-product-card [product]="product"></app-product-card>
              </div>
            </div>
          </div>

        </ng-template>
      </div>
    </div>
  </div>
  <!-- <div class="offcanvas offcanvas-start p-4" tabindex="-1" id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header d-flex justify-content-end">
      <div style="cursor: pointer">
        <i class="far fa-times" style="font-size: 24px;" data-bs-dismiss="offcanvas"></i>
      </div>
    </div>
    <app-sidebar-product-filter></app-sidebar-product-filter>
  </div>
  <div class="products-panel-wrapper">
    <div class="row">
      <div class="col-2 d-flex justify-content-center">
        <button class="btn d-xl-none button-menu" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" >
          <i class="far fa-filter-list" style="font-size: 24px;"></i>
        </button>

      </div>
      <div class="col-10">
        <app-breadcrumbs [familysBreadcrumbs]="familyBreadcrumns"></app-breadcrumbs>
      </div>
    </div>
    <div class="product-cards-wrapper">
      <div class="row">
        <div class="col-3 p-3 product-card" *ngFor="let product of productsArray" (click)="viewProduct(product.id)">
          <div class="product-mouse-effect">
            <app-product-card [product]="product"></app-product-card>
          </div>
        </div>
      </div>
    </div>
  </div> -->

</div>