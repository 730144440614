<div class="container content-wrapper">
    <div class="text-wrapper">
        <p class="p1"><em>En virtud del cumplimiento de la Ley 34/2002, de
                11 de Julio, de Servicios de la Sociedad de la Información y
                de Comercio Electrónico, le informamos:</em></p>
        <h2>1. Objeto.</h2>
        <p class="p1"><strong>Art. 10 LSSI: <a href="https://marefix.com">www.marefix.com</a></strong>
            es un dominio en internet de titularidad de MAREFIX, S.L. con
            Domicilio Social a Pol. industrial La Variante &#8211; C/
            Grajera, nº 6 (provincia de La Rioja), CIF nº B26532143 La
            empresa consta inscrita en el Registro Mercantil de Inscrita en
            el Registro Mercantil de La Rioja, Tomo 797, Hoja LO-16591,Folio
            31, Inscripción 1ª.</p>
        <p class="p1"><strong>A efectos de este documento el teléfono de
                contacto es 941447825 y el correo electrónico de contacto es
                <a href="mailto:mmg@marefix.com">mmg@marefix.com</a>.</strong>
        </p>
        <p class="p1">Este Aviso Legal regula la utilización de dicho
            dominio.</p>
        <p class="p1" style="text-align: left;">La utilización de este sitio
            web implica la aceptación por parte del Usuario de las
            condiciones de uso incluidas en este Aviso como también nuestras
            políticas sobre ‘<a href="https://marefix.com/data-protection"><strong>Protección
                    de Datos y Privacidad</strong></a>’ y ‘<a
                href="https://marefix.com/cookies-policy/"><strong>Política
                    de uso de Cookies</strong></a>’. En el caso de que
            ciertos servicios contenidos y/o herramientas ofrecidos a través
            de este “Portal” requirieran de la aplicación condiciones
            particulares estas se pondrán a disposición del Usuario.<br />
            Por otra parte, MAREFIX, S.L. advierte que, tanto los contenidos
            y servicios de esta página web como las propias condiciones de
            utilización, pueden ser modificados sin notificación previa.</p>
        <h2>2. Condiciones de utilización.</h2>
        <p class="p1">El Usuario se compromete a que, en los apartados en
            que sea necesario que se registre para poder acceder a los
            mismos, facilitar datos veraces, exactos y completos sobre su
            identidad. Además se compromete a mantener actualizados los
            datos personales que pudieran ser proporcionados a titular del
            dominio, por lo tanto, único responsable de las falsedades o
            inexactitudes que realice.</p>
        <p class="p1">Se informa que en caso de ser menor de edad deberá
            obtener el permiso de sus padres, tutores o representantes
            legales para poder acceder a los servicios prestados. MAREFIX,
            S.L. no se responsabiliza en el caso de que los datos sobre este
            tema sean inexactos o falsos.</p>
        <p class="p1">El “portal” sólo puede ser utilizado con propósitos
            legales por tanto el usuario se obliga a hacer un uso lícito y
            honrado del portal y conforme a las presentes Condiciones
            Generales de Uso, a No utilizar los servicios del “portal” para
            la realización de actividades contrarias a las legislación
            española, a la moral y al orden público, asumiendo por parte del
            usuario todas las responsabilidades de daños y perjuicios frente
            al titular del dominio o terceros que pudieran derivarse de
            prácticas ilegales o no permitidas entres otras y a titulo
            enunciativo y no limitativo:</p>
        <p class="p1" style="padding-left: 30px;">Realizar sin previo
            consentimiento por parte del titular del dominio cualquier
            manipulación o alteración de esta página, no asumiendo el
            titular del dominio ninguna responsabilidad que pudiera
            derivarse, de dicha manipulación o alteración por terceros.</p>
        <p class="p1" style="padding-left: 30px;">Realizar cualquier acto
            que pueda dañar, inutilizar, sobrecargar, o deteriorar el Portal
            y los servicios y/o impedir el normal uso y utilización por
            parte de los Usuarios.</p>
        <p class="p1" style="padding-left: 30px;">Introducir y/o Utilizar
            programas de ordenador, datos, archivos defectuosos, virus,
            código malicioso, equipos informáticos o de telecomunicaciones o
            cualquier otro, independientemente de su naturaleza que pueda
            causar daños en el Portal, en cualquiera de los servicios, o en
            cualesquiera activos (físicos o lógicos) de los sistemas de
            información de titular del dominio.</p>
        <p class="p1" style="padding-left: 30px;">Violar los derechos de
            terceros a la intimidad, la propia imagen, la protección de
            datos al secreto en las comunicaciones, a la propiedad
            intelectual e industrial.</p>
        <p class="p1" style="padding-left: 30px;">Ocultar y falsear el
            origen de mensajes de correo electrónico.</p>
        <p class="p1" style="padding-left: 30px;">Utilizar identidades
            falsas, suplantar la identidad de otros en la utilización del
            Portal o en la utilización de cualquiera de los servicios.</p>
        <p class="p1" style="padding-left: 30px;">Reproducir, distribuir,
            modificar o copiar el contenido de esta página, salvo que de
            disponga de la autorización del titular del dominio o esté
            legalmente autorizado.</p>
        <p class="p1" style="padding-left: 30px;">Transmitir a terceros no
            autorizados los nombres de Usuario y las claves de acceso.</p>
        <p class="p1">MAREFIX, S.L. no responde de los Enlaces (LINKS) a
            otras páginas de Internet de terceros y su existencia no implica
            que MAREFIX, S.L. apruebe o acepte sus contenidos y servicios.
            Estas otras páginas web no están controladas por MAREFIX, S.L.
            ni cubiertas por la presente Política de Privacidad. Si accede a
            otras páginas web utilizando los Links proporcionados, los
            operadores de dichos sitios web podrán recoger su información
            personal. Asegúrese que está conforme con las Políticas de
            Privacidad de estas terceras páginas web antes de facilitar
            ningún tipo de información personal.</p>
        <p class="p1">Con carácter general, el titular del dominio, excluye
            su responsabilidad por los daños y perjuicios de cualquier
            naturaleza e índole que pudieran derivarse del uso del sitio web
            de, así como a los daños y perjuicios derivados de la infracción
            de los derechos de propiedad Intelectual e Industrial por parte
            de los usuarios y/o la falta de veracidad, exactitud, y
            actualidad de los contenidos, ni le podrán ser exigidas
            responsabilidades por la interrupción del servicio, inadecuado
            funcionamiento o imposibilidad de acceso al servicio.</p>
        <p class="p1">El titular del dominio no será responsable por los
            daños y perjuicios causados por la presencia de virus o
            cualquier otro software lesivo que pueda producir alteraciones
            en el sistema informático del Usuario.</p>
        <p class="p1">El sitio web, incluyendo a título enunciativo pero no
            limitativo, su programación, diseños, logotipos, texto y/o
            gráficos son propiedad del prestador o en su caso dispone de
            licencia o autorización expresa por parte de los autores.</p>
        <p class="p1">Independientemente de la finalidad para la que fueran
            destinados, la reproducción total o parcial, uso, explotación,
            distribución y comercialización, requiere en todo caso de la
            autorización escrita previa por parte del titular del dominio.
        </p>
        <p class="p1">El usuario se compromete a no realizar ningún acto en
            contra de los derechos de propiedad intelectual o industrial del
            autor.</p>
        <p class="p1">El prestador autoriza expresamente a que terceros
            puedan redirigir directamente a los contenidos concretos del
            sitio web, debiendo en todo caso redirigir al sitio web
            principal del prestador.</p>
        <h2 class="p3">3. Uso de cookies</h2>
        <p class="p1">MAREFIX, S.L. como titular de esta web declara que
            utiliza procedimientos automáticos de recogida de información
            para guardar el registro de los Usuarios que visitan su página
            web. Pulsa aquí para acceder en nuestra política de uso de
            cookies <strong><a href="https://marefix.com/cookies-policy/">Política
                    de cookies</a></strong></p>
        <h2 class="p3">4. Protección de datos</h2>
        <p class="p1">Puede consultar nuestra política sobre protección de
            datos de carácter personal al siguiente apartado <strong><a
                    href="https://marefix.com/data-protection">Protección
                    de datos</a></strong> de nuestra web corporativa:
            <strong><a href="https://marefix.com">www.marefix.com</a></strong>.
        </p>
        <h2 class="p3">5. Legislación aplicable</h2>
        <p class="p1">Las presentes Condiciones se regirán en todo momento
            por lo dispuesto en la legislación española.</p>
    </div>
</div>