import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getAdresses() {
    return this.http.get<any>(
      `${AppSettings.API_URI}/directionsMe`, { headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  postAddress(data) {
    return this.http.post<any>(
      `${AppSettings.API_URI}/addDirection`, data ,{ headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  putAddress(data, id) {
    return this.http.put<any>(
      `${AppSettings.API_URI}/editDirection/${id}`, data ,{ headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  deleteAddress(id) {
    return this.http.delete<any>(
      `${AppSettings.API_URI}/removeDirection/${id}`,{ headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getAddressId(id) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/direction/${id}`,{ headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}
