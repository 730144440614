<div class="date-picker">
  <mat-form-field>
    <mat-label>Rango de fechas</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Fecha inicio" (dateChange)="startDataChange($event)"
        [(ngModel)]="start_date_aux">
      <input matEndDate placeholder="Fecha Fin" (dateChange)="endDataChange($event)" [(ngModel)]="end_date_aux">
    </mat-date-range-input>
    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>

<div *ngIf="!date_complete || orders.length == 0" class="info-picker">
  No hay pedidos en el rango de fecha seleccionado
</div>

<div *ngIf="download">
  <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple">Descargando...</ngx-spinner>
</div>

<div *ngFor="let order of orders; index as i;" class="user-data-card">
  <div class="profile-info">
    <div class="row">
      <div class="col-md-8">
        <p class="profile-title">Pedido - {{order.CodOrder}}</p>
      </div>
      <div class="col-md-4 icon-adrress-row">
        <fa-icon (click)="downloadPDF(order.IDOrder,order.CodOrder)" style="cursor: pointer; font-size: 30px;"
          class="icon-address-edit" [icon]="faDownload"></fa-icon>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="row d-flex align-items-center">
          <div class="col-4">
            <p class="info-label bold-class">Cliente</p>
          </div>
          <div class="col-8">
            <p class="info-data bold-class">{{order.Description}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-4">
            <p class="info-label">Fecha</p>
          </div>
          <div class="col-8">
            <p class="info-data">{{getDateFormat(order.OrderDate) | date: 'dd/MM/YYYY'}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6 col-sm-12">
        <div class="row d-flex align-items-center">
          <div class="col-4">
            <p class="info-label">Cliente</p>
          </div>
          <div class="col-8">
            <p class="info-data">{{order.Description}}</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-md-6 col-sm-12">
        <div class="row">
          <div class="col-4">
            <p class="info-label"></p>
          </div>
          <div class="col-8">
            <p class="info-data"></p>
          </div>
        </div>
      </div> -->
      <div class="col-md-6 col-sm-12" *ngIf="order.CodOrderWeb != null">
        <div class="row d-flex align-items-center">
          <div class="col-4">
            <p class="info-label bold-class">ID Carrito Web</p>
          </div>
          <div class="col-8">
            <p class="info-data bold-class">{{order.CodOrderWeb}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>