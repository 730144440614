<div class=" content-wrapper">
  <div id="document-data" *ngIf="completeLoaded">
    <div class="container">
      <div class="row">
        <div class="col-5">
          <div class="logo">
            <img src="/assets/images/logo-marefix-pedidos.png"/>
          </div>
          <div class="contact-data">
            <p class="bold c-title">MAREFIX,S.L.U</p>
            <p>Pol. Ind. La Variante, Calle La Grajera, 7</p>
            <p>26140 - Lardero - ESPAÑA</p>
            <p>TLF: (0034) 941447825</p>
            <p>E-mail: marefix@marefix.com</p>
            <p>Web: www.marefix.com</p>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-5">
          <div class="border-container text-center bold">
            ALBARÁN
          </div>
          <div class="pt-3"></div>
          <div class="border-container data-client">
            <p>{{ userData['CompanyName'] }}</p>
            <p>{{ userData['Address'] }}</p>
            <p>{{ userData['LineAddressCode'] }}</p>
            <p>CIF: {{ userData['VatNumber'] }}</p>
          </div>
        </div>
      </div>
      <div class="separator-doc"></div>
      <div class="row w-100 subtitle-doc">
        <div class="col-1 border-container">
          <div class="row">
            <div class="col-12 h-34 border-container bold">
              Número Albarán
            </div>
            <div class="col-12">
              {{order['CodDeliveryNote']}}
            </div>
          </div>
        </div>
        <div class="col-1 border-container">
          <div class="row">
            <div class="col-12 h-34 border-container bold">
              Fecha
            </div>
            <div class="col-12 ">
              {{ getDateFormat(order['DeliveryNoteDate'])}}
            </div>
          </div>
        </div>
        <div class="col-1 border-container">
          <div class="row">
            <div class="col-12 h-34 border-container bold">
              Código Cliente
            </div>
            <div class="col-12 ">
              {{order['CodCustomer']}}
            </div>
          </div>
        </div>
        <div class="col-2 border-container bold">
          <div class="row">
            <div class="col-12 h-34">
              Forma de pago
            </div>
            <div class="col-12 border-container">
              {{order['PaymentMethod']['Description']}}
            </div>
          </div>
        </div>
        <div class="col-5 border-container bold">
          <div class="row">
            <div class="col-12 border-container ">
              Forma de envío
            </div>
            <div class="col-7 border-container">Portes</div>
            <div class="col-5 border-container">Medio/Expedición</div>
            <div class="col-7 border-container"></div>
            <div class="col-5 border-container "></div>
          </div>
        </div>
        <div class="col-2 border-container bold"></div>
      </div>
      <div class="separator-doc"></div>
      <div class="doc-products-container">
        <div class="row">
          <div class="col-2 border-container bold">Código</div>
          <div class="col-2 border-container bold">Refencia</div>
          <div class="col-3 border-container bold">Concepto</div>
          <div class="col-1 border-container bold">Cantidad</div>
          <div class="col-1 border-container bold">Precio</div>
          <div class="col-1 border-container bold">DT%</div>
          <div class="col-1 border-container bold">DT%</div>
          <div class="col-1 border-container bold">Importe</div>
        </div>
        <div class="row border-container">
          <div class="row" *ngFor="let line of order.DeliveryNoteLines" style="margin: 0; padding: 0;">
            <div class="col-2 border-order">{{line.CodArticle}}</div>
            <div class="col-2 border-order"></div>
            <div class="col-3 border-order">{{line.Description}}</div>
            <div class="col-1 border-order">{{line.Quantity}}</div>
            <div class="col-1 border-order">{{line.Price}} €</div>
            <div class="col-1 border-order">{{line.Discount1}}</div>
            <div class="col-1 border-order">{{line.Discount2}}</div>
            <div class="col-1 border-order">{{line.Amount}} €</div>
          </div>
        </div>
      </div>
      <div class="separator-doc"></div>
      <div class="footer-doc">
        <div class="row">
          <div class="col-2 border-container bold">DTO.P.PAGO</div>
          <div class="col-2 border-container bold">PORTES</div>
          <div class="col-2 border-container bold">BASE IMPONIBLE</div>
          <div class="col-2 border-container bold">IVA</div>
          <div class="col-2 border-container bold">REC.EQUIVALENCIA</div>
          <div class="col-2 border-container bold">IMPORTE TOTAL</div>

          <div class="col-2 border-container"></div>
          <div class="col-2 border-container"></div>
          <div class="col-2 border-container">{{order['VATBase1']}} €</div>
          <div class="col-2 border-container">{{order['VATPercentage1']}}%<span style="float: right;">{{order['VATAmount1']}} €</span></div>
          <div class="col-2 border-container">{{order['VATPercentage2']}}%<span style="float: right;">{{order['VATAmount2']}} €</span></div>
          <div class="col-2 border-container">{{ order['TotalDeliveryNote'] }} €</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="separator"></div>
    <div class="buttons mb-5">
        <button type="button" class="btn btn-success" (click)="openPDF(order['CodDeliveryNote'])">Descargar Albarán</button>
    </div>
  </div>
</div>

