import { Platform } from '@angular/cdk/platform';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HeaderComponent implements OnInit {
  public isLoggedIn = new BehaviorSubject<boolean>(false);
  openedMegamenu: boolean = false;

  constructor(private dialog: MatDialog,
    private platform: Platform,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastEvokeService: ToastEvokeService,
    private cartService: CartService) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('jwt_token') === null) {
      this.isLoggedIn.next(false);
    } else {
      this.isLoggedIn.next(true);
      this.cartService.getCart().subscribe(data => {
        if (data) {
          let cartIcon = document.getElementById('cart-icon')
          if (data.lineas.length > 0) {
            cartIcon.style.visibility = 'visible';
            cartIcon.innerHTML = data.lineas.length;
          }
        }
      })
    }

  }

  ngAfterViewInit() {

    if (!this.platform.ANDROID && !this.platform.IOS) {

      var megamenu = document.getElementById('megamenu')
      var navItems = document.querySelectorAll('.hide-megamenu')

      // navItems.forEach(link => {
      //   link.addEventListener("mouseover", function (event) {
      //     megamenu.style.display = 'none'
      //   })
      // });

      // if (window.innerWidth <= 1999) {
      //   navItems.forEach(link => {
      //     link.addEventListener("mouseover", function (event) {
      //       megamenu.style.display = 'block'
      //     })
      //   });

      // } else {
      //   navItems.forEach(link => {
      //     link.addEventListener("mouseover", function (event) {
      //       megamenu.style.display = 'none'
      //     })
      //   });
      // }

      // element.addEventListener("mouseover", function (event) {
      //   megamenu.style.display = 'block'
      // })
      // megamenu.addEventListener("mouseout", function (event) {
      //   megamenu.style.display = 'none'
      // })

      // megamenu.addEventListener("mouseover", function (event) {
      //   megamenu.style.display = 'block'
      // })
    }

  }

  openMegamenu(open: boolean) {
    this.openedMegamenu = open;
    let style = open ? 'block' : 'none';
    var megamenu = document.getElementById('megamenu')
    megamenu.style.display = style
  }

  onResize(event) {
    if (!this.platform.ANDROID && !this.platform.IOS) {
      var megamenu = document.getElementById('megamenu')
      var navItems = document.querySelectorAll('.hide-megamenu')

      // navItems.forEach(link => {
      //   link.addEventListener("mouseover", function (event) {
      //     megamenu.style.display = 'none'
      //   })
      // });
      // if (event.target.innerWidth <= 1999) {
      //   navItems.forEach(link => {
      //     link.addEventListener("mouseover", function (event) {
      //       megamenu.style.display = 'block'
      //     })
      //   });

      // } else {
      //   navItems.forEach(link => {
      //     link.addEventListener("mouseover", function (event) {
      //       megamenu.style.display = 'none'
      //     })
      //   });
      // }
    }

  }

  goToLogin() {
    this.authenticationService.check().subscribe(data => {
      this.router.navigate(['profile']);
    }, error => {
      this.router.navigate(['login']);
    });
  }

  goToCart() {
    this.authenticationService.check().subscribe(data => {
      this.router.navigate(['cart']);
    }, error => {
      this.toastEvokeService.danger('ERROR', 'Es necesario iniciar sesión').subscribe();
    });
  }
  goToSearch() {
    this.router.navigate(['product']);
  }


  openMenu() {
    // const dialogRef = this.dialog.open(MainMenuComponent, {
    //   width: '440px',
    //   panelClass: ['main-menu-dialog', 'animate_slideRight'],
    //   position: {
    //     top: '0px',
    //     right: '0px'
    //   }
    // })
  }


}
