import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSubfamilyService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getProductSubfamilysByLine(line: string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/productSubFamily?lineID=` + line+ '&page=1&items=50', { headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
  getProductSubfamilyByFamily(line: string, family: string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/productSubFamily?lineID=` + line + `&familyID=` + family + '&page=1&items=50', { headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}
