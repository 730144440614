import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });

  getInvoice(id:string): any {
    return this.http.get<any>(
      `${AppSettings.API_URI}/InvoiceRPSbyIdDownloadPDF/`+ id, { headers: this.reqHeader, responseType:'text' as 'json' }
    ).pipe(
      map(data => {
        const byteArray = new Uint8Array(atob(data).split('').map((char) => char.charCodeAt(0)));
        return new Blob([byteArray], { type: 'data:application/pdf'});
      }, error => {
        return false;
      })
    )
  }

  getOrder(id:string): any {
    return this.http.get<any>(
      `${AppSettings.API_URI}/OrderRPSbyIdDownloadPDF/`+ id, { responseType:'text' as 'json' }
    ).pipe(
      map(data => {
        const byteArray = new Uint8Array(atob(data).split('').map((char) => char.charCodeAt(0)));
        return new Blob([byteArray], { type: 'data:application/pdf'});
      }, error => {
        return false;
      })
    )
  }

  getDelivery(id:string): any {
    return this.http.get<any>(
      `${AppSettings.API_URI}/DeliveryRPSbyIdDownloadPDF/`+ id, { responseType:'text' as 'json' }
    ).pipe(
      map(data => {
        const byteArray = new Uint8Array(atob(data).split('').map((char) => char.charCodeAt(0)));
        return new Blob([byteArray], { type: 'data:application/pdf'});
      }, error => {
        return false;
      })
    )
  }

}
