import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppSettings } from '../app-settings';

@Injectable({
  providedIn: 'root'
})
export class ProductLineService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getProductLines() {
    return this.http.get<any>(
      `${AppSettings.API_URI}/productLine`, { headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}
