<div class="sidebar-wrapper">
    <div class="lines-wrapper">
        <div class="line " *ngFor=" let line of lines">
            <div class="line-wrapper row d-flex align-items-center " (click)="getLineProduct(line)">
                <div class="col-2 d-flex justify-content-center">
                    <i class="fa-light"
                        [ngClass]="showedSubfamilys && selectedFamily==line.ProductLineID ? 'fa-circle-minus' : 'fa-circle-plus' "></i>
                </div>
                <div class="col-10">
                    <div class="line-name">
                        {{line.ProductLineDescription | titlecase}}
                    </div>
                </div>
            </div>
            <div class="subfamily" *ngIf="familys.length>0 && selectedFamily==line.ProductLineID">
                <div class="row">
                    <div class="p-0 col-2 d-flex justify-content-center ">
                        <div class="vl"></div>
                    </div>
                    <div class="p-0 col-10">
                        <div class="subfamily-wrapper">
                            <div class="subfamily-name" *ngFor="let family of familys">
                                <p (click)="showSubfamily(family)" >{{family.ProductFamilyDescription | titlecase}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="filters-wrapper">
        <h3>Filtros</h3>
        <div class="custom-select">
            <select>
                <option value="0">Ordenar por:</option>

            </select>
        </div>


    </div> -->
</div>