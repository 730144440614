import { Component } from '@angular/core';
import { OrdersService } from '../../../services/orders.service';
import { faDownload, faEye} from '@fortawesome/pro-regular-svg-icons';
import { DocumentService } from '../../../services/documents.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent {
  start_date: any = null;
  end_date: any = null;
  faDownload = faDownload;
  faEye = faEye;

  initial_load_data: boolean = false;

  date_complete: boolean = false;

  invoices: [] = [];

  public download: boolean = false;

  constructor(
    private ordersService:OrdersService,
    private documentService:DocumentService,
    public spinner: NgxSpinnerService
  ) {
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: true,
      bdColor: "rgba(0, 0, 0, 0.3)",
    });
  }

  startDataChange(event) {
    this.end_date = null;
    this.start_date = event.value._i;

    if(this.start_date !== null && this.end_date !== null) {
      this.date_complete = true;
      this.getInvoices(
        1,
        this.start_date.year + "-" + this.start_date.month + "-" + this.start_date.date,
        this.end_date.year + "-" + this.end_date.month + "-" + this.end_date.date
      );
    }
  }

  endDataChange(event) {
    this.end_date = event.value._i;

    if(this.start_date !== null && this.end_date !== null) {
      this.date_complete = true;
      this.getInvoices(
        1,
        this.start_date.year + "-" + this.start_date.month + "-" + this.start_date.date,
        this.end_date.year + "-" + this.end_date.month + "-" + this.end_date.date
      );
    }
  }

  getInvoices(page, date_from, date_to) {
    this.ordersService.getInvoicesByCustomer(page, date_from, date_to).subscribe(
      (data) => {
        this.invoices = data.Items;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getDateFormat(date) {
    const date_convert = new Date(date);

    return date_convert;

    return date_convert.getDate() + "/" + (date_convert.getMonth()+1) + "/" + date_convert.getFullYear()
  }

  downloadPDF(id, codinv) {
    this.download = true;
    this.documentService.getInvoice(id).subscribe(
      (data) => {
        var fileName = codinv+'.pdf';
        saveAs(data, fileName);
        this.download = false;
      }
    );
  }

  vieDocument(id) {
    window.open('document/invoice?id='+id, "_blank");
  }
}
