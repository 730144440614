import { Platform } from '@angular/cdk/platform';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public screenWidth: any;
  public screenHeight: any;
  public hideCursor: boolean;
  constructor(private platform: Platform) {
    this.screenWidth= window.innerWidth;
    this.screenHeight= window.innerHeight;
    if (this.platform.ANDROID || this.platform.IOS) {
      this.hideCursor=true;
    }
  }



}
