import { Component } from '@angular/core';

@Component({
  selector: 'app-hero-swiper',
  templateUrl: './hero-swiper.component.html',
  styleUrls: ['./hero-swiper.component.scss']
})
export class HeroSwiperComponent {
  public imgSlide = ['../'];
  public slideNumbers = [1, 2, 3, 4, 5, 6]
  public imgURL = "../../../assets/images/home/slide/slider-";

  constructor() {
    // console.log(this.slideNumbers);

  }

}
