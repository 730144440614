import { Component, Input, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() product: any;
  public imgPath: string;
  public defaultImg:string;
  ngOnInit(): void {
    // this.imgPath= "../../../assets/images/products/product_example.png"
    this.imgPath=AppSettings.API_ASSETS+"productos/";
    this.defaultImg = "../../../../assets/images/products/screw_decorations.png"
  }

  isLogged() {
    if (localStorage.getItem('jwt_token') != null) {
      return true;
    }

    return false;
  }
}
