import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsComponent } from 'src/app/components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { ProductLineService } from 'src/app/services/product-line.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [BreadcrumbsComponent]
})
export class ProductsComponent {
  menuItem: any = { id: 1, name: 'Fijaciones metálicas', icon: 'icon_1.svg' }
  menuItemsArray = [];
  loadLines = false;
  constructor(private productLineService: ProductLineService, private router: Router, private breadcrumbsService: BreadcrumbsService) {

  }

  ngOnInit(): void {
    this.productLineService.getProductLines().subscribe((data) => {
      if (data?.Items) {
        this.loadLines = true;
        data.Items.forEach(item => {
          this.menuItemsArray.push({ id: item.$id, ProductLineID: item.ProductLineID, name: String(item.ProductLineDescription).toLocaleLowerCase(), icon: 'icon_1.svg' })
        })
      }
    })
  }
  getProductLine(item) {
    this.router.navigate(['product/' + item.ProductLineID], { queryParams: { productLineID: item.ProductLineID } });
    let breadcrumbs = this.breadcrumbsService.breadcrums.getValue();
    breadcrumbs.line = { name: item.name, id: item.ProductLineID }
    this.breadcrumbsService.updateBreadcrums(breadcrumbs)
  }

}
