import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

/**
 * JWT
 */
import { JwtInterceptor } from './helpers/jwt.interceptor';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './components/shared/header/header.component';
import { HeroSwiperComponent } from './components/hero-swiper/hero-swiper.component';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Parallax, Virtual } from 'swiper';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import { SwiperModule } from 'swiper/angular';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/shared/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductsCategoriesComponent } from './pages/products-categories/products-categories.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { SidebarProductFilterComponent } from './components/sidebar-product-filter/sidebar-product-filter.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { MegaMenuProductsComponent } from './components/shared/mega-menu-products/mega-menu-products.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PersonalDataComponent } from './pages/profile/personal-data/personal-data.component';
import { AddressesComponent } from './pages/profile/addresses/addresses.component';
import { OrdersComponent } from './pages/profile/orders/orders.component';
import { ReservationsComponent } from './pages/profile/reservations/reservations.component';
import { DocumentsComponent } from './pages/profile/documents/documents.component';
import { CartComponent } from './pages/cart/cart.component';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { ProductsComponent } from './pages/products/products.component';
import { LegalAdviceComponent } from './pages/legal-advice/legal-advice.component';
import { DataProtectionComponent } from './pages/data-protection/data-protection.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { ProductFamilyCardComponent } from './components/product-family-card/product-family-card.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';
import { CookieService } from 'ngx-cookie-service';

//import { NgbDate, NgbCalendar, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

// Import from library
import {
  NgxAwesomePopupModule,
  DialogConfigModule,
  ConfirmBoxConfigModule,
  ToastNotificationConfigModule
} from '@costlydeveloper/ngx-awesome-popup';
import { AddressDialogComponent } from './components/address-dialog/address-dialog.component';
import { DocumentComponent } from './pages/document/document.component';
import { CookiesDialogComponent } from './components/cookies-dialog/cookies-dialog.component';

SwiperCore.use([
  Navigation, Pagination, Scrollbar, A11y, Parallax, Virtual
]);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    ContactFormComponent,
    SearchbarComponent,
    HeroSwiperComponent,
    ProductsCategoriesComponent,
    ProductDetailComponent,
    SidebarProductFilterComponent,
    BreadcrumbsComponent,
    ProductCardComponent,
    MegaMenuProductsComponent,
    ProfileComponent,
    PersonalDataComponent,
    AddressesComponent,
    OrdersComponent,
    ReservationsComponent,
    DocumentsComponent,
    CartComponent,
    AddToCartComponent,
    ProductsComponent,
    LegalAdviceComponent,
    DataProtectionComponent,
    CookiesPolicyComponent,
    ProductFamilyCardComponent,
    AddressDialogComponent,
    DocumentComponent,
    InvoiceComponent,
    DeliveryComponent,
    CookiesDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    SwiperModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    NgxSkeletonLoaderModule,
    NgxAwesomePopupModule.forRoot({
      colorList: {
        success  : '#82bb27'
      }
    }),
    DialogConfigModule.forRoot(),
    ConfirmBoxConfigModule.forRoot(),
    ToastNotificationConfigModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
