import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private activatedRouter: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    var token = localStorage.getItem('jwt_token');
    if (token === null) {
      //this.router.navigate(['/']).then(() => this.authenticationService.logout());
      return of(false);
    }

    var decoded: any = jwt_decode(token);
    // console.log(decoded);
    if (decoded && decoded.username) {
      this.authenticationService.check().subscribe(
        (data) => {
          if(data) {
            this.router.navigate(['/profile']);
          }
        },
        (error) => {
          this.router.navigate(['/login']).then(() => location.reload());
        }
      )
    } else {
      this.authenticationService.logout();
      this.router.navigate(['/']).then(() => location.reload());
      return of(false);
    }
  }
}
