<div class="container content-wrapper">
    <div class="text-wrapper">
        <h1>Protección de datos</h1>
        <h1>Política de Protección de datos de MAREFIX, S.L.</h1>
        <h3>1. ¿Quién es el Responsable de tratamiento de sus datos?</h3>
        <p>El responsable de los tratamientos de datos referentes a los distintos procesos con
            respecto la gestión de usuarios, clientes y clientes potenciales, colaboradores y
            proveedores es MAREFIX, S.L., con domicilio social en Pol. industrial La Variante
            &#8211; C/ Grajera, nº 626140 Lardero (provincia de La Rioja) y CIFB26532143 .</p>
        <p><b>A efectos de nuestra política de protección de datos el teléfono de contacto es
                941447825 y el correo electrónico de contacto es <a
                    href="mailto:mmg@marefix.com">mmg@marefix.com</a>.</b>
        </p>
        <p>Asimismo, le informamos que en el tratamiento de datos en materia de gestión de
            Usuarios, Clientes y Proveedores, no existen entidades Corresponsables del
            Tratamiento.</p>
        <h3>2. ¿Qué tipo de datos tenemos sobre su persona y cómo los hemos obtenido?</h3>
        <p>Las categorías de datos personales que MAREFIX, S.L. trata sobre sus clientes y
            proveedores son:</p>
        <ul>
            <li>Datos de identificación</li>
            <li>Direcciones postales o electrónicas</li>
            <li>Información comercial</li>
            <li>Datos económicos y de transacciones</li>
            <li>Imagen</li>
            <li>Datos profesionales</li>
            <li>Datos curriculares</li>
            <li>Datos sobre estilo de vida</li>
        </ul>
        <p>En ningún caso tratamos datos especialmente protegidos.</p>
        <p>Todos los datos arriba mencionados los hemos obtenido o directamente de Usted
            mediante la presentación de una oferta comercial, propuesta contractual, etc. o
            mediante su empresa al facilitarnos los datos de identificación y demás información
            necesaria para llevar al cabo el objeto dela relación contractual entre las partes.
            Será una obligación suya o de su empresa facilitarnos los datos actualizados en caso
            de modificación. Con respecto a los datos de candidatos a empleo hemos obtenido sus
            datos mediante la presentación de un CV por su parte o la obtención de su
            candidatura mediante los servicios de un portal de empleo. En este último caso Usted
            ha autorizado dicha cesión de sus datos a nuestra empresa al insertar sus datos
            curriculares en el portal de empleo.</p>
        <h3>3. ¿Por cuánto tiempo conservaremos sus datos?</h3>
        <p>Los datos personales relativos a personas físicas vinculadas a clientes potenciales,
            clientes y proveedores que MAREFIX, S.L. recopile mediante los distintos formularios
            de contacto y/o recogida de información se conservarán mientras no se solicite su
            supresión por el interesado. Los datos proporcionados por nuestros clientes y
            proveedores se conservarán mientras se mantenga la relación mercantil entre las
            partes respetando en cualquier caso los plazos mínimos legales de conservación según
            la materia.</p>
        <p>En cualquier caso MAREFIX, S.L. guardará sus datos personales durante el período de
            tiempo que sea razonablemente necesario teniendo en cuenta nuestras necesidades de
            dar respuesta a cuestiones que se planteen o resolver problemas, realizar mejoras,
            activar nuevos servicios y cumplir los requisitos que exija la legislación
            aplicable. Esto significa que podemos conservar tus datos personales durante un
            período de tiempo razonable incluso después de que hayas dejado de usar nuestros
            productos o de que hayas dejado de usar esta página web. Después de este período,
            tus datos personales serán eliminados de todos los sistemas de MAREFIX, S.L.</p>
        <h3>4. ¿Con qué finalidad y sobre qué base de legitimación tratamos sus datos?</h3>
        <p>En MAREFIX, S.L. tratamos los datos que nos facilitan las personas interesadas con el
            fin de gestionar distintas actividades derivadas de procedimientos específicos
            realizados en materia de ventas, servicio post venta, gestión de proveedores,
            gestión de candidatos, calidad de servicios, etc. De esta manera, utilizaremos sus
            datos para llevar al cabo algunas de las siguientes acciones basándonos en la
            respectiva base de legitimación:</p>
        <table cellspacing="0" cellpadding="0">
            <tbody>
                <tr>
                    <td class="conborde" valign="top">FINALIDAD DE TRATAMIENTO</td>
                    <td class="conborde" valign="top">BASE DE LEGITIMACIÓN</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">SISTEMA DE ALARMA CON CAPTACION DE
                        IMÁGENES POR DETECCIÓN DE MOVIMIENTO</td>
                    <td class="conborde" valign="top">Interés legitimo del responsable</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión de los derechos de protección de
                        datos de los interesados</td>
                    <td class="conborde" valign="top">Obligación regulatoria</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión contable, fiscal, administrativa y
                        facturación de clientes</td>
                    <td class="conborde" valign="top">Ejecución (pre)contrato; Obligación
                        regulatoria</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión de las solicitudes de información
                        que llegan a través del formularios web o redes sociales</td>
                    <td class="conborde" valign="top">Consentimiento del afectado</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión de operaciones de publicidad y
                        marketing para la fidelización de clientes</td>
                    <td class="conborde" valign="top">Interés legitimo del responsable</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión fiscal, contable y administrativa
                        con colaboradores profesionales, comisionistas, etc.</td>
                    <td class="conborde" valign="top">Ejecución (pre)contrato; Obligación
                        regulatoria</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Gestión fiscal, contable y administrativa
                        con proveedores</td>
                    <td class="conborde" valign="top">Ejecución (pre)contrato; Obligación
                        regulatoria</td>
                </tr>
                <tr>
                    <td class="conborde" valign="top">Procesos de búsqueda, evaluación y
                        selección de candidatos</td>
                    <td class="conborde" valign="top">Consentimiento del afectado</td>
                </tr>
            </tbody>
        </table>
        <p>En cuanto, a la base de legitimación referenciada Usted se encuentra obligado a
            facilitar los datos personales, en el supuesto de que no facilite sus datos de
            carácter personal no se podrá ejecutar su contrato, cumplir las obligaciones legales
            o derivadas de los poderes públicos.</p>
        <p>No vamos a elaborar perfiles comerciales en base de la información facilitada y en
            consecuencia tampoco tomaremos decisiones automatizadas sobre Ud en base de un
            perfil comercial.</p>
        <h3>5. ¿A qué destinatarios se comunicarán sus datos?</h3>
        <p>MAREFIX, S.L. nunca compartirá tus datos personales con ninguna empresa tercera que
            pretenda utilizarlos en sus acciones de marketing directo, excepto en el caso de que
            nos hayas autorizado expresamente a ello.</p>
        <p>Le informamos que podemos facilitar tus datos personales a organismos de la
            Administración Pública y Autoridades competentes en aquellos casos que MAREFIX, S.L.
            reciba un requerimiento legal por parte de dichas Autoridades o en los casos que
            actuando de buena fe, consideramos que tal acción es razonablemente necesaria para
            cumplir con un proceso judicial; para contestar cualquier reclamación o demanda
            jurídica; o para proteger los derechos de MAREFIX, S.L. o sus clientes y el público
            en general.</p>
        <p>Le informamos que sus datos o datos de terceras personas comunicadas por Usted
            (Cónyuges, hijos, etc) no van a ser cedidos o comunicados a terceros salvo
            obligación legal, siendo la empresa la única responsable para su tratamiento y
            custodia.</p>
        <p>MAREFIX, S.L. puede proporcionar tus datos personales a terceras personas (Ej.
            proveedores de servicios de Internet que nos ayudan a administrar nuestra página web
            o llevar a cabo los servicios contratados, empresas de soporte y mantenimiento
            informático, empresas de logística, gestorías y asesoramiento fiscal y contable,
            etc.). En cualquier caso, estas terceras personas deben mantener, en todo momento,
            los mismos niveles de seguridad que MAREFIX, S.L. en relación a tus datos personales
            y, cuando sea necesario, estarán vinculadas por compromisos legales a fin de guardar
            tus datos personales de forma privada y segura, y a fin de utilizar únicamente la
            información siguiendo instrucciones específicas de MAREFIX, S.L.</p>
        <p>Le informamos que nuestra entidad no realiza transferencias internacionales de datos
            en materia de gestión de Usuarios, Clientes y/o Proveedores.</p>
        <h3>6. ¿Cuáles son sus derechos como afectado o interesado?</h3>
        <p>Cualquier persona tiene derecho a obtener confirmación sobre si MAREFIX, S.L. está
            tratando datos personales que le conciernan, o no.<span class="Apple-converted-space"> </span></p>
        <p>En concreto, las personas interesadas pueden solicitar el <b>derecho de acceso</b> a
            sus datos personales, así como recibirlos en un formato común y lectura mecanizada
            si el tratamiento se efectúa por medios electrónicos (<b>derecho de
                portabilidad</b>).</p>
        <p>Asimismo, las personas interesadas pueden solicitar el <b>derecho de
                rectificación</b> de los datos inexactos o, en su caso, solicitar su
            <b>supresión</b> cuando, entre otros motivos, los datos ya no sea necesarios para
            los fines que fueron recogidos.<span class="Apple-converted-space"> </span>
        </p>
        <p>Complementariamente, en determinadas circunstancias, los interesados podrán solicitar
            la <b>limitación del tratamiento de sus datos</b>, o en determinadas circunstancias
            y por motivos relacionados con su situación particular, los interesados podrán
            ejercitar su <b>derecho a oponerse</b> al tratamiento de sus datos. MAREFIX, S.L.
            dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o
            la defensa de posibles reclamaciones o en aquellas excepciones establecidas en la
            normativa aplicable.</p>
        <p>Asimismo, le informamos que tiene derecho a retirar sus consentimientos otorgados en
            cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el
            consentimiento previo a su retirada.</p>
        <p>Asimismo se informa al Usuario que en cualquier momento puede ejercitar los
            mencionados derechos dirigiéndose por escrito a<span class="Apple-converted-space">
            </span>nosotros utilizando los datos de contacto que aparecen en el Apartado 1,
            ‘Responsable de tratamiento’ de la presente política de Protección de Datos y
            Privacidad de MAREFIX, S.L., adjuntando copia de su DNI.Al igual modo, puede
            enviarnos un correo electrónico a la dirección <a
                href="mailto:mmg@marefix.com"><strong>mmg@marefix.com</strong></a>.</p>
        <p>Usted también tendrá el derecho a presentar una reclamación ante la Agencia Española
            de Protección de datos, especialmente cuando no haya obtenido satisfacción en el
            ejercicio de sus derechos.</p>
        <p><b>Agencia Española de Protección de Datos:<br />
            </b><b>C/ Jorge Juan, 6<br />
            </b><b>28001 – Madrid<br />
            </b><b>Telf. 901100099 / 912663517</b></p>
        <h3>7. Protección de datos de los usuarios de la página web</h3>
        <p>De conformidad con el vigente Reglamento (UE) 2016/679, MAREFIX, S.L<span class="Apple-converted-space">.
            </span>informa que los datos de carácter
            personal de los Usuarios del sitio web se tratarán para la actividad del tratamiento
            indicado en cada formulario de recogida de datos de nuestra página web por parte de
            MAREFIX, S.L. Dicho tratamiento de sus datos estará amparado en su propio
            consentimiento. Al pulsar el botón “ENVIAR”, el Usuario consiente al tratamiento de
            sus datos por parte de MAREFIX, S.L.</p>
        <p>Asimismo, le informamos que salvo obligación legal o consentimiento expreso por su
            parte MAREFIX, S.L. no va a ceder sus datos a terceras personas.</p>
        <p>Igualmente, se informa al Usuario que en cualquier momento puede ejercitar los
            derechos de acceso, rectificación<span class="Apple-converted-space"> </span>o
            supresión de datos así como disponer de otros derechos reconocidos en el presente
            documento y regulados en el Reglamento (UE) 2016/679, notificándolo a MAREFIX, S.L.,
            Pol. industrial La Variante &#8211; C/ Grajera, nº 626140 Lardero (provincia de La
            Rioja) , e-mail: <a href="mailto:mmg@marefix.com"><strong>mmg@marefix.com</strong></a>. Por otro
            lado, de acuerdo con lo dispuesto en la Ley 34/2002, de 11 de Julio, de Servicios de
            la Sociedad de Información y de Comercio Electrónico, MAREFIX, S.L. se compromete a
            no enviar publicidad a través del correo electrónico sin haber recabado antes la
            expresa autorización del destinatario. El Usuario podrá oponerse al envío de
            publicidad marcando la casilla correspondiente.</p>
        <h3>8. Otra información de interés sobre nuestra política de privacidad</h3>
        <h5>8.1 Medidas de Seguridad</h5>
        <p>MAREFIX, S.L. adopta los niveles de seguridad requeridos por la normativa Europea y
            Española vigente en protección de datos teniendo en cuenta el estado de la técnica,
            los costes de aplicación y la naturaleza, el alcance, el contexto y los fines del
            tratamiento descritos, así como los riesgos de probabilidad y gravedad variables
            para sus derechos y libertades como persona.</p>
        <h5>8.2 Tratamiento de datos de menores</h5>
        <p>Al amparo del RGPD UE 679/2016 y de la LOPDGDD 3/2018, los menores de más de 14 años
            pueden dar su consentimiento a la contratación de servicios de la sociedad de la
            información, como puede ser la inscripción en un foro, la cumplimentación de un
            formulario de contacto, etc. No obstante, será responsabilidad de MAREFIX, S.L.
            comprobar la veracidad de la edad indicada por parte del menor.</p>
        <p>Para el tratamiento de datos de menores de 14 años dicha recogida de datos se
            realizará siempre bajo el expreso consentimiento de los padres o tutores
            legales.<span class="Apple-converted-space"> </span></p>
        <h5>8.3 Modificaciones de nuestra Política de Protección de Datos y de Privacidad</h5>
        <p>Ocasionalmente, MAREFIX, S.L. podrá realizar modificaciones y correcciones en este
            apartado de Política de Protección de Datos para Clientes, Proveedores y Usuarios.
            Por favor, verifique este apartado regularmente para consultar los cambios que
            puedan haber existido y de qué manera le pueden afectar.</p>
        <h5>8.4 ¿Por qué es necesario aceptar está Política de Protección de Datos y de
            Privacidad?</h5>
        <p>Este apartado de Política de Protección de Datos para Clientes, Proveedores y
            Usuarios le proporciona de un modo fácilmente accesible toda la información
            necesaria para que puedas conocer la tipología de datos que MAREFIX, S.L<span
                class="Apple-converted-space">.
            </span>mantiene sobre sus clientes potenciales,
            clientes y/o proveedores, las finalidades perseguidas, los derechos que la normativa
            de protección de datos reconoce a Ud. como persona afectada y el modo de cómo
            ejercer dichos derechos. Por lo tanto, con el envío deliberado de sus datos
            personales mediante nuestros medios de contacto y/o con el inicio de la relación
            mercantil con nuestra empresa consideramos que reconoce y acepta el tratamiento de
            tus datos personales tal y como se describe en la presente política. Esta
            información personal solamente se usará para los fines para los cuales nos la has
            facilitado o determinadas normativas nacionales o regionales nos habilitan
            hacerlo.<span class="Apple-converted-space"> </span></p>
        <p>En cualquier caso, debemos advertirle que una negativa por su parte para facilitarnos
            determinados datos solicitados podría obstaculizar el desarrollo de la relación
            contractual entre las partes con posibles consecuencias serias al momento de prestar
            las diversas prestaciones contempladas dentro del contrato mercantil celebrado con
            la parte contratante.</p>
        <p>Si tienes cualquier pregunta en relación a este apartado de Política de Protección de
            Datos para los Clientes Potenciales, Clientes y Proveedores de MAREFIX, S.L., por
            favor, póngase en contacto con la empresa usando la dirección facilitada en la
            Apartado primero ‘Responsable de Tratamiento’ y estaremos encantados de atenderte y
            dar respuesta a las cuestiones adicionales que nos quieras plantear.</p>
        <h3>9. Legislación aplicable</h3>
        <p>Las presentes Condiciones se regirán en todo momento por lo dispuesto en la
            legislación española y europea en materia de protección de datos de carácter
            personal y de privacidad.</p>
    </div>
</div>