<div class="footer-container">
    <div class="container">
        <app-contact-form></app-contact-form>
        <div class="footer-wrapper" style="color: white;">
            <div class="row" style="color: white;">
                <div class="col-12 col-lg-5">
                    <p class="footer-info">Más de 40 años de experiencia en el Sector</p>
                </div>
                <div class="col-12 col-lg-7">
                    <div class="location-wrapper">
                        <p class="footer-info-location">Pol. Ind. La Variante - C. La Grajera, 7 .</p>
                        <p class="footer-info-location">26140 Lardero, La Rioja </p>
                        <a href="https://goo.gl/maps/xq2a2MjZizkFdxhu8" target="_blank">
                            <p class="footer-info-link footer-links">Ver en Google Maps</p>
                        </a>
                    </div>
                    <div class="phone-wrapper">
                        <a href="tel:+34941447825" style="text-decoration: none;">
                            <p class="footer-info-location">+34 941 447 825</p>
                        </a>
                        <a href="mailto:marefix@marefix.com">
                            <p class="footer-info-email footer-links">marefix@marefix.com</p>
                        </a>
                    </div>
                    <!-- <div class="social-media-wrapper d-flex">
                        <div class="twitter-wrapper social-media-icon p-2">
                            <i class="fa-brands fa-twitter"></i>
                        </div>
                        <div class="linkedin-wrapper social-media-icon p-2">
                            <i class="fa-brands fa-linkedin"></i>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row footer-copyright">
                <div class="col-6 col-lg-3 order-1 order-xl-0 mt-4 mt-xl-0">
                    <span><i class="fa-light fa-copyright"></i> {{now|date:'y'}}. MAREFIX S.L</span>
                </div>
                <div class="col-12 col-lg-6 order-0">
                    <div class="privacy-data d-flex justify-content-between flex-column flex-sm-row">
                        <a class="footer-link" href="/legal-advice"><span>Aviso Legal</span></a>
                        <a class="footer-link" href="/data-protection"> <span>Protección de datos</span></a>
                        <a class="footer-link" href="/cookies-policy"><span>Política de cookies</span></a>
                    </div>
                </div>
                <div class="col-6 col-lg-3 order-2 mt-4 mt-xl-0 d-flex justify-content-end">
                    <a class="footer-link" href="https://www.sdi.es/"> <span>Desarrollado con <i
                                class="fa-solid fa-heart"></i> por SDi</span></a>
                </div>
            </div>
        </div>
    </div>
</div>