<form class="contact-form-footer">
  <div class="footer-text footer-text-wrapper">
    <div class="">
      <div class="">¿Tienes alguna duda? </div>
      <div class="txt-underline">Contáctanos</div>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="w-100 form-right">
        <input class="input-custom m-0" type="text" placeholder="Nombre" matInput>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100 form-right">
        <input class="input-custom m-0" type="text" placeholder="Apellidos" matInput>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100 form-right">
        <input class="input-custom m-0" type="email" placeholder="Correo electrónico" matInput>
      </mat-form-field>
    </div>
    <div class="col-6">
      <mat-form-field class="w-100 form-right">
        <input class="input-custom m-0" type="tel" placeholder="Teléfono" matInput>
      </mat-form-field>
    </div>
  </div>
  <div class="section-form">
    <div class="d-flex justify-content-between align-items-center">
      <p class="m-0 footer-text" style="font-size: 16px;">Cuéntanos más sobre tu proyecto</p>
    </div>
    <div style="margin-top: 12px;" (click)="inputComent.focus()">
      <textarea matInput #inputComent class="textarea-custom" maxlength="2500" type="textarea" name="message"
        placeholder="Escribe aquí.."></textarea>
    </div>
  </div>
  <button class="btn-primary">
    Enviar
  </button>
</form>