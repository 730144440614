<div class="container">
    <div class="user-data-card" *ngIf="initial_load_data">
        <div class="profile-info">
            <p class="profile-title">Información</p>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <p class="info-label">Empresa</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.CompanyName}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-4">
                            <p class="info-label">Correo electrónico</p>
                        </div>
                        <div class="col-8">
                          <p *ngFor="let email of processEmail(user_data.Email)" class="info-data p-email">{{email}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <p class="info-label">CIF/NIF</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.VatNumber}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <p class="info-label">Teléfono</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.Phone1}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Agentes comerciales ngfor -->
        <div class="profile-info">
            <p class="profile-title">Dirección</p>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <p class="info-label">Calle</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.Address}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col-4">
                            <p class="info-label">Ciudad</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.City}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">

                    <div class="row d-flex align-items-center">
                        <div class="col-4">
                            <p class="info-label">País</p>
                        </div>
                        <div class="col-8">
                            <p class="info-data">{{user_data.CountryDescription}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row buton-close-session">
      <div clas="col-12">
        <div class="button-wrapper">
          <div (click)="logout()" class="btn-primary">Cerrar Sesion</div>
        </div>
      </div>
    </div>
</div>
