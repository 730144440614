import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  public familysBreadcrumbs: any[]
  isDragging = false;
  startX;
  breadcrumbsObj;
  @ViewChild('parent') scrollableDiv: ElementRef;

  constructor(public breadcrumbsService: BreadcrumbsService, private router: Router) {
    console.log(breadcrumbsService.breadcrums.getValue())

  }

  ngOnInit(): void {
    this.breadcrumbsObj = this.breadcrumbsService.breadcrums.getValue();
    var scrollableDiv = document.getElementById('scrollable-div');
    var isDragging = false;
    var startPosition;
    var startScrollLeft;

    scrollableDiv.addEventListener('mousedown', function (event) {
      isDragging = true;
      startPosition = event.clientX;
      startScrollLeft = scrollableDiv.scrollLeft;
      scrollableDiv.style.scrollBehavior = 'initial';
    });

    scrollableDiv.addEventListener('mouseup', function () {
      isDragging = false;
      scrollableDiv.style.scrollBehavior = 'smooth';
    });

    scrollableDiv.addEventListener('mousemove', function (event) {

      if (!isDragging) return;
      var distance = event.clientX - startPosition;
      scrollableDiv.scrollLeft = startScrollLeft - distance;
    });
  }
  redirectLine() {
    if (this.breadcrumbsObj?.family) {
      delete this.breadcrumbsObj.family;
      this.router.navigate(['product/' + this.breadcrumbsObj?.line.id], { queryParams: { productLineID: this.breadcrumbsObj?.line.id } });
      this.breadcrumbsService.updateBreadcrums(this.breadcrumbsObj)
    }
  }


  // dragIn(event) {
  //   this.isDragging = true;
  //   this.startX = event.pageX - this.scrollableDiv.nativeElement.offsetLeft;
  //   console.log('start');

  // }

  // dragOut() {
  //   this.isDragging = false;
  //   console.log('end');

  // }

  // dragging(event) {
  //   console.log('dragin');
  //   if (!this.isDragging) return;

  //   event.preventDefault();
  //   var scrollX = event.pageX - this.scrollableDiv.nativeElement.offsetLeft - this.startX;
  //   this.scrollableDiv.nativeElement.scrollLeft = scrollX;

  // }
}
