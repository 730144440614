<div class="CookieConsent" *ngIf="!cookieActive">
    <div class="" style="flex: 1 1 100%; margin: 0px; padding: 0px;"><img src="../../../assets/images/home/cookies.png"
            alt="Stars" loading="lazy"
            style=" color:rgba(0, 0, 0, 0.55) ;position: absolute; top: 0px; width: 64px; left: 50%; transform: translate(-50%, -50%);">
        <p class="c-kWnKGo c-kWnKGo-hanxqg-variant-caption1 c-kWnKGo-idZRbmA-css">Usamos cookies para las funciones
            esenciales de nuestra web y ofrecerles un mejor servicio</p><a target="_self" rel="noreferrer"
            href="/cookies-policy" class="link-cookies">Política de cookies</a>
    </div>
    <div><button class="accept-cookies" (click)="cookiesAccept()" aria-label="Accept cookies">Aceptar</button>
    </div>
</div>