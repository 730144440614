<div class="product-card-wrapper ">
  <div class="product-img-wrapper">
    <img class="" [src]='imgPath+""+product.ProductSubFamilyID+".jpg"'
      onerror="this.onerror=null; this.src='../../../../assets/images/products/screw_decorations.png'" alt="">
  </div>
  <div class="product-card-info ">
    <div class="product-name">
      <!--<p>{{product.ProductSubFamilyDescription.split(',')[product.ProductSubFamilyDescription.split(',').length-1]}}</p>-->
      <p>{{product.ProductSubFamilyCode}}</p>
    </div>
    <div class="product-description ">
      <p>{{product.ProductSubFamilyDescription}}</p>
    </div>
  </div>
  <div class="see-more-link ">
    <!-- <span *ngIf="!isLogged(); else userlogged">Leer más <i class="fa-regular fa-chevron-right"></i></span> -->
    <ng-template #userlogged>
      <span>Comprar <i class="fa-regular fa-chevron-right"></i></span>
    </ng-template>
  </div>
</div>