import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Guards
 */
import { AuthGuard } from './guards/auth.guard';

/**
 * Components
 */

import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { ProductsCategoriesComponent } from './pages/products-categories/products-categories.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PersonalDataComponent } from './pages/profile/personal-data/personal-data.component';
import { AddressesComponent } from './pages/profile/addresses/addresses.component';
import { DocumentsComponent } from './pages/profile/documents/documents.component';
import { OrdersComponent } from './pages/profile/orders/orders.component';
import { ReservationsComponent } from './pages/profile/reservations/reservations.component';
import { CartComponent } from './pages/cart/cart.component';
import { LegalAdviceComponent } from './pages/legal-advice/legal-advice.component';
import { DataProtectionComponent } from './pages/data-protection/data-protection.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { ProductsComponent } from './pages/products/products.component';
import { DocumentComponent } from './pages/document/document.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { DeliveryComponent } from './pages/delivery/delivery.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', component: HomeComponent },
  {
    path: 'products', component: ProductsComponent, children: [

    ]
  },
  { path: 'product', component: ProductsCategoriesComponent },
  { path: 'product/:idLine', component: ProductsCategoriesComponent },
  { path: 'product/:idLine/:idFamily', component: ProductsCategoriesComponent },
  { path: 'product/:idLine/:idFamily/:idSubfamily', component: ProductDetailComponent },




  // { path: 'product/:idProduct', component: ProductDetailComponent },
  { path: 'cart', component: CartComponent },
  { path: 'legal-advice', component: LegalAdviceComponent },
  { path: 'data-protection', component: DataProtectionComponent },
  { path: 'cookies-policy', component: CookiesPolicyComponent },
  { path: 'product-lines', component: ProductsComponent },
  { path: 'document/order', component: DocumentComponent, canActivate: [AuthGuard] },
  { path: 'document/invoice', component: InvoiceComponent, canActivate: [AuthGuard] },
  { path: 'document/delivery', component: DeliveryComponent, canActivate: [AuthGuard] },
  {
    path: 'profile', component: ProfileComponent, canActivate: [AuthGuard],
    children: [
      { path: 'profile/personal-information', component: PersonalDataComponent },
      { path: 'profile/addresses', component: AddressesComponent },
      { path: 'profile/documents', component: DocumentsComponent },
      { path: 'profile/orders', component: OrdersComponent },
      { path: 'profile/reservations', component: ReservationsComponent },
    ]
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
