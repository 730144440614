import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../services/profile.service';
import { AddressDialogComponent } from '../../../components/address-dialog/address-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { faPenToSquare, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {
  ConfirmBoxInitializer,
  DialogLayoutDisplay,
  DisappearanceAnimation,
  AppearanceAnimation
} from '@costlydeveloper/ngx-awesome-popup';

@Component({
  selector: 'app-addresses',
  templateUrl: './addresses.component.html',
  styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
  public faPenToSquare = faPenToSquare;
  public faTrash = faTrash;
  public addresses: any = [];

  constructor(
    private profileService: ProfileService,
    private dialog: MatDialog,
    private toastEvokeService: ToastEvokeService
  ) {

  }

  ngOnInit(): void {
    this.profileService.getAdresses().subscribe(
      data => {
        console.log(data);
        this.addresses = data;
      }, error => {

      }
    )
  }

  editAddress(index) {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      panelClass: 'generic-dialog',
      data: {
        title: 'Editar Dirección',
        edit: true,
        address: this.addresses[index]
      }
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        const cursor = document.getElementById('cursor');


        if (data) {
          this.toastEvokeService.success('Direcciones', 'Dirección modificada correctamente').subscribe();

          this.profileService.getAdresses().subscribe(
            data => {
              console.log(data);
              this.addresses = data;
            }, error => {

            }
          )
        }
      }
    );
  }

  createAddress() {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      panelClass: 'generic-dialog',
      data: {
        title: 'Crear Dirección',
        edit: false,
        address: this.addresses
      }
    });

    dialogRef.afterClosed().subscribe(
      (data) => {
        const cursor = document.getElementById('cursor');


        if (data) {
          this.toastEvokeService.success('Direcciones', 'Dirección creada correctamente').subscribe();

          this.profileService.getAdresses().subscribe(
            data => {
              console.log(data);
              this.addresses = data;
            }, error => {

            }
          )
        }
      }
    );
  }

  deleteAddress(id) {
    const newConfirmBox = new ConfirmBoxInitializer();

    newConfirmBox.setTitle('Eliminar direccíon');
    newConfirmBox.setMessage('Estás seguro de que quieres elminar esta dirección');

    // Choose layout color type
    newConfirmBox.setConfig({
    layoutType: DialogLayoutDisplay.DANGER,
    animationIn: AppearanceAnimation.BOUNCE_IN,
    animationOut: DisappearanceAnimation.BOUNCE_OUT,
    });

    newConfirmBox.setButtonLabels('Continuar', 'Cancelar');

    // Simply open the popup
    newConfirmBox.openConfirmBox$().subscribe(resp => {
      if(resp.clickedButtonID == 'continuar'){
        this.profileService.deleteAddress(id).subscribe(data => {
          this.toastEvokeService.success('Direcciones', 'Dirección eliminada').subscribe();

          this.profileService.getAdresses().subscribe(
            data => {
              console.log(data);
              this.addresses = data;
            }, error => {

            }
          )
        }, error => {
          this.toastEvokeService.danger('Direcciones', 'No se ha podido eliminar la dirección').subscribe();
        })
      }
    });
  }
}
