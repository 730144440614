<div class="content-wrapper product-page-wrapper">
  <div class="container">
    <!-- <app-breadcrumbs></app-breadcrumbs> -->
    <div class="row" style="margin-top: 20px;">
      <div class="col-12 col-md-7  ">
        <div class="swiper-product-img">
          <!-- <swiper #swiperProductCover [loop]="true" [zoom]="true" [spaceBetween]="10"
                        [thumbs]="{ swiper: thumbsSwiper }" class="swiperCoverImg">
                        <ng-template swiperSlide *ngFor="let img of imgs">
                            <div class="img-swiper-wrapper swiper-zoom-container"><img [src]="img" /></div>
                        </ng-template>
                    </swiper>
                    <div class="swiper-thumbnail-wrapper d-flex align-items-center">
                        <div (click)="previousSlide()" class="swiper-button-prev-unique swiper-button-pag">
                            <div class="button-pag-icon">
                                <i class="fa-thin fa-circle-chevron-left"></i>
                            </div>
                        </div>
                        <swiper #swiperProductThumbnails (swiper)="thumbsSwiper = $event" [spaceBetween]="15"
                            [slidesPerView]="4" [freeMode]="true" class="swiperThumbnail" [watchSlidesProgress]="true">
                            <ng-template swiperSlide *ngFor="let img of imgs">
                                <div class="img-swiper-wrapper"><img [src]="img" /></div>
                            </ng-template>
                        </swiper>
                        <div (click)="nextSlide()" class="swiper-button-next-unique swiper-button-pag">
                            <div class="button-pag-icon">
                                <i class="fa-thin fa-circle-chevron-right"></i>
                            </div>
                        </div>
                    </div> -->
          <div class="img-swiper-wrapper swiper-zoom-container d-flex justify-content-center"><img
              onerror="this.onerror=null; this.src='../../../../assets/images/products/screw_decorations.png'"
              style="width: 70%; margin: auto; max-height: 500px; object-fit: contain;" [src]="imgPath" /></div>
        </div>
      </div>
      <div class="col-12 col-md-5">
        <div class="product-detail">
          <div class="product-name">
            <!-- <h1>{{subfamilyCode}}</h1> -->
            <h2>{{subfamilyDescription}}</h2>
          </div>
          <!-- <div class="product-detail-info">
            <div class="product-unit-price">
              <p style='font-family:"Switzer-Light"'>Desde {{precio}}{{precioString}}</p>
            </div>
            <div class="product-stocks d-flex">
              <div class="stock-card" *ngIf="envaseA!=null">
                <div class="stock-icon">
                  <i class="fa-thin fa-box"></i>
                </div>
                <div class="stock-units">
                  <p>{{envaseA}} uds.</p>
                </div>
              </div>
              <div class="stock-card" *ngIf="envaseB!=null">
                <div class="stock-icon">
                  <i class="fa-thin fa-boxes-stacked"></i>
                </div>
                <div class="stock-units">
                  <p>{{envaseB}} uds.</p>
                </div>
              </div>
            </div> -->
          <!-- <div class="product-colors d-flex align-items-center">
                            <p class="color-label">Color</p>
                            <div class="color-wrapper d-flex align-items-center justify-content-center color-selected">
                                <div class="color-select"></div>
                            </div>
                            <div class="color-wrapper d-flex align-items-center justify-content-center color-selected">
                                <div class="color-select"></div>
                            </div>
                        </div> -->
          <!-- <div class="product-description">
                            <ul>
                                <li>Tornillo autotaladrante</li>
                                <li>Cabeza alomada baja </li>
                                <li>Arandela estampada</li>
                                <li>Phillips</li>
                            </ul>
                        </div> -->
          <!-- </div> -->
          <!-- <div class="select-options d-flex">
                        <div class="select-size select-wrapper">
                            TBEZ3509 - 3.5 X 9.5 X 6MM
                            <i class="fa-light fa-chevron-down"></i>
                        </div>
                        <div class="select-units select-wrapper">
                            <i style="margin-right: 15px;" class="fa-light fa-chevron-down"></i>
                            1000 Uds.
                            <i style="margin-left: 15px;" class="fa-light fa-chevron-up"></i>
                        </div>
                    </div> -->
          <!-- <div class="btn-secondary">Añadir al carrito</div> -->
        </div>
      </div>
    </div>
    <h2 class="w-100" style="padding-bottom: 25px; padding-top: 65px ;border-bottom: 2px solid #82bb27;">Ficha del
      producto</h2>

    <p style="padding-bottom: 25px; padding-top: 25px;" *ngIf="showTables.length===0 && !loadedProducts">No hay
      registros para este
      artículo</p>

    <div class="articles-table" *ngFor="let table of showTables">
      <h3 style="font-size: 22px;" *ngIf="table.tableType.name!=null">{{table.tableType.name}}</h3>
      <div class="headers">
        <div class="row scroll">
          <div class="col header-item-col" *ngFor="let header of table.tableHeaders">
            <p *ngIf="header.type=='string'" style="font-weight: bold;">{{header.value}}</p>
            <img *ngIf="header.type=='img'" [src]="imgPathAPI+'cabeceras/'+header.value" alt=""
              style="max-width: 80px; max-height: 50px; margin-bottom: 5px;">
          </div>
          <div *ngIf="isLogged" class="col"></div>
        </div>
        <div style="position: relative" class="spinner-item" *ngIf="loadedProducts; else productsLoaded">
          <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple">
            <p class="spinner-text"> Cargando... </p>
          </ngx-spinner>
        </div>
        <ng-template #productsLoaded>
          <div class="row article-item d-flex align-items-center scroll" *ngFor="let dataRow of table.listProducts">
            <div class="col article-item-col" *ngFor="let field of table.tableFields">
              <!-- <p><span>{{dataRow[field]}}</span><span *ngIf="field == 'Euro100'"> €</span></p> -->
              <p><span>{{dataRow[field]}}</span></p>
            </div>
            <div *ngIf="isLogged" class="col article-item-col" (click)="addToCart(dataRow)" style="cursor: pointer;">
              <i style="color:#82bb27" class="fa-regular fa-cart-shopping"></i> <span
                style="color:#82bb27; padding-left: 3px;">Comprar</span>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <!-- <div class="related-products-wrapper">
            <h2>Productos relacionados</h2>
            <div class="row">
                <div class="related-product-card col-2" *ngFor="let product of relatedProducts">
                    <app-product-card [product]="product"></app-product-card>
                </div>
            </div>
        </div> -->
  </div>
</div>