<div class="container-sm content-wrapper">
  <div class="row" style="margin-top: 40px;">
    <div class="col-6">
      <h1 class="title-cart">Carrito</h1>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <div class="btn-secondary button-cart" (click)="endCart()">Finalizar pedido</div>
    </div>
  </div>
  <div class="cart">
    <div class="cart-header row">
      <div class="col-4">Producto</div>
      <div class="col-2 text-center">Precio €/1</div>
      <div class="col-2 text-center">Cantidad</div>
      <div class="col-2 text-center">Total</div>
      <div class="col-1 text-center">Editar</div>
      <div class="col-1 text-center">Eliminar</div>
      <div class="line col-12"></div>
    </div>
    <div class="row spinner-item" *ngIf="loadCart; else cartLoad">
      <div style="position: relative">
        <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple">
          <p class="spinner-text"> Cargando productos del carrito </p>
        </ngx-spinner>
      </div>
    </div>
    <ng-template #cartLoad>
      <div class="cart-content-items">
        <div class="row" *ngIf="!emptyCart">
          No hay productos añadidos al carrito
        </div>
        <div class="cart-item row fadeIn align-items-center" *ngFor="let item of cartItems; let i = index">
          <div class="col-4 d-flex align-items-center">
            <div class="img-article-cart" style="margin-right: 20px" *ngIf="item.image">
              <img style="width: 40px; height: 40px; object-fit: contain;" src="{{item.image}}" />
            </div>
            <span style="font-weight: bold;">{{item.articulo.CodigoSubFamilia}}</span> - {{item.cod_producto}}
          </div>
          <div class="col-2 text-center">{{formatNumber(item.articulo.price)}}</div>
          <div class="col-2 text-center">{{item.cantidad}}</div>
          <div class="col-2 text-center">{{formatNumber(item.articulo.price * item.cantidad)}}</div>
          <div class="col-1 text-center cursor-click"><fa-icon class="icon-cart-line" [icon]="faPenToSquare"
              (click)="editItem(item.id_linea, item.cod_producto, item.cantidad, item.paquete)"></fa-icon></div>
          <div class="col-1 text-center cursor-click"><fa-icon class="icon-cart-line-trash" [icon]="faTrash"
              (click)="removeItem(item.id_linea)"></fa-icon></div>
        </div>
      </div>
      <div class="row" *ngIf="emptyCart">
        <div class="col-md-8 col-sm-12 address-cart">
          <div class="row">
            <div class="col-12">
              <h1 class="title-address w-100">Dirección de entrega</h1>
              <div class="line-totals"></div>
              <select class="form-select form-select-sm w-50" [value]="idAddressCart"
                (change)="selectAddress($event.target.value)">
                <option selected disabled class="slected-mat-input">Seleccionar dirección</option>
                <option *ngFor="let address of addresses" value="{{address.id}}">{{address.alias}}</option>
              </select>
            </div>
            <div class="col-12">
              <div class="row card-address-position">
                <div class="col-12">
                  <div *ngIf="idAddressCart" class="user-data-card">
                    <div class="col-12">
                      <div class="row d-flex align-items-center">
                        <div class="col-12">
                          <p class="address-card-line address-card-line-company text-uppercase">{{cardAddress.company}}
                          </p>
                          <p class="address-card-line text-uppercase">{{cardAddress.name}}</p>
                          <p class="address-card-line text-uppercase">{{cardAddress.adress}}</p>
                          <p class="address-card-line text-uppercase">{{cardAddress.city}}</p>
                          <p class="address-card-line text-uppercase">{{cardAddress.country}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12">
          <div class="cart-totals float-right">
            <div class="row justify-content-end">
              <div class="col-12">
                <h1 class="title-totals">Resumen</h1>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="line-totals"></div>
            </div>
            <div class="row justify-content-end fadeIn">
              <div class="cart-line-back col-6" style="padding-left: 30px;">Base bruta</div>
              <div class="cart-line-back line-right-item   col-6">{{formatNumber(totalPriceItems)}}</div>
            </div>
            <div class="row justify-content-end fadeIn">
              <div class="col-6" style="padding-left: 30px;">Portes</div>
              <div class="line-right-item  col-6">{{formatNumber(portesPrice)}}</div>
            </div>
            <div class="row justify-content-end fadeIn">
              <div class="cart-line-back col-6">Base Imponible</div>
              <div class="cart-line-back line-right-item  col-6">
                {{formatNumber(totalPriceItems + portesPrice)}}
              </div>
            </div>
            <div class="row justify-content-end fadeIn">
              <div class="col-6">IVA ({{vatPercent}}%)</div>
              <div class="line-right-item col-6">
                {{formatNumber((totalPriceItems + portesPrice)*(vatPercent/100))}}
              </div>
            </div>
            <div *ngIf="vatPercent2" class="row justify-content-end fadeIn">
              <div class="cart-line-back col-6">R.Equivalencia ({{vatPercent2}}%)</div>
              <div class="cart-line-back line-right-item col-6">
                {{formatNumber((totalPriceItems + portesPrice)*(vatPercent2/100))}}
              </div>
            </div>
            <div *ngIf="!vatPercent2; else rec" class="row justify-content-end fadeIn">
              <div class="cart-line-back cart-total-end col-6">Total</div>
              <div class="cart-line-back line-right-item  cart-total-end col-6">
                {{formatNumber((totalPriceItems + portesPrice)+((totalPriceItems + portesPrice)*(vatPercent/100)))}}
              </div>
            </div>
            <ng-template #rec>
              <div class="row justify-content-end fadeIn">
                <div class="cart-line-back cart-total-end col-6">Total</div>
                <div class="cart-line-back line-right-item  cart-total-end col-6">
                  {{formatNumber((totalPriceItems + portesPrice)+((totalPriceItems +
                  portesPrice)*(vatPercent/100))+((totalPriceItems + portesPrice)*(vatPercent2/100)))}}
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>