import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { element } from 'protractor';
import { CartService } from 'src/app/services/cart.service';
import { ArticlesService } from 'src/app/services/articles.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter, take, tap } from 'rxjs';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
})


export class AddToCartComponent {

  public envases: any[] = [];
  public selected: boolean = false;
  public size: number = 0;
  public quantity: number = 1;
  public totalQuantity: number;
  public default: any;
  public price: any;
  public totalPrice: any;
  public perUnit: any;
  public article: any;
  public loadedPrice: boolean = false;
  public endCart: boolean = false;

  public titleDialog: string = 'Añadir al Carrito';
  public buttonDialog: string = 'Añadir al Carrito';

  loading: boolean = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA)
  public data: any,
    private dialogRef: MatDialogRef<AddToCartComponent>,
    private cartService: CartService,
    public authenticationService: AuthenticationService,
    public toast: ToastEvokeService,
    public articlesService: ArticlesService,
    private router: Router
  ) {

    this.article = data.article;


    if (this.article.hasOwnProperty('quantity')) {
      this.quantity = this.article.quantity;
    }

    if (this.article.hasOwnProperty('title')) {
      this.titleDialog = this.article.title;
    }

    if (this.article.hasOwnProperty('button')) {
      this.buttonDialog = this.article.button;
    }

    if ((this.article?.EnvaseA != null) || (this.article?.EnvaseB != null)) {
      if (this.article?.EnvaseA) {
        this.envases.push(this.article.EnvaseA);
      }
      if (this.article?.EnvaseB) {
        this.envases.push(this.article.EnvaseB);
      }
    } else {
      this.envases.push(1);
    }
    this.size = this.envases[0]
    this.default = this.envases[0]
    this.totalQuantity = this.quantity * this.envases[0]

    this.totalPrice = this.getPrice(this.article.codigo, this.quantity, this.article);
    router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.dialogRef.close();
      }
    });
  }

  getPrice(sku, cantidad, article) {
    this.loadedPrice = true;

    this.cartService.getPriceSKU({ "sku": sku, "cantidad": cantidad }).subscribe(price_data => {
      if (article.Euro100 !== null) {
        this.totalPrice = this.formatNumber((price_data.Price * (this.size / 100)) * cantidad, 3);
        this.loadedPrice = false;
        return;
      }
      if (article.EuroUnidad !== null) {
        this.totalPrice = this.formatNumber((price_data.Price * (this.size)) * cantidad, 3);
        this.loadedPrice = false;
        return;
      }
      this.totalPrice = this.formatNumber((price_data.Price) * cantidad, 3);

      this.loadedPrice = false;
    })
  }

  changeSize($event) {
    this.size = $event.value
    this.updateUnits()
  }
  changeQuantity($event) {
    this.totalQuantity = $event
    this.updateUnits();

    if (this.quantity == null) {
      this.totalPrice = this.formatNumber(0, 3)
    }
  }

  updateUnits() {
    this.quantity = Math.ceil(this.totalQuantity / this.size);

    if (this.totalQuantity !== null) {
      this.totalPrice = this.getPrice(this.article.codigo, this.quantity, this.article);
    }
  }

  formatNumber(floatValue = 1, decimals = 3, multiplier = 1) {
    let floatMultiplied = floatValue * multiplier;
    let stringFloat = floatMultiplied + "";
    let arraySplitFloat = stringFloat.split(".");
    let decimalsValue = "0";
    if (arraySplitFloat.length > 1) {
      decimalsValue = arraySplitFloat[1].slice(0, decimals);
    }
    let integerValue = arraySplitFloat[0];
    let arrayFullStringValue = [integerValue, decimalsValue];
    let FullStringValue = arrayFullStringValue.join(".")
    let floatFullValue = parseFloat(FullStringValue) + "";
    let formatFloatFullValue = new Intl.NumberFormat('de-DE', { minimumFractionDigits: decimals, style: 'currency', currency: 'EUR' }).format(Number(floatFullValue));
    return formatFloatFullValue;
  }

  addLine() {
    this.loading = true;
    this.authenticationService.getUsersMe().subscribe(data => {
      const tprice = Number(this.totalPrice.replace(".", "").replace(",", ".").replace("€", "").replace(" ", ""));
      if (tprice < data.Riesgo) {
        this.articlesService.getArticleMarefixBySKU(this.article.codigo).subscribe(articledata => {
          this.cartService.getCart().subscribe(cart => {
            if (articledata.Stocks.length == 0) {
              this.loading = false;
              this.toast.danger('Error', 'Artículo no disponible');
              return;
            }

            if (this.totalQuantity < ((articledata.Stocks[0].Quantity) * 0.8)) {
              if (this.article.hasOwnProperty('section')) {
                if (this.article.section === 'cart') {
                  this.dialogRef.close({ 'quantity': this.totalQuantity, 'message': 'Cantidad modificada correctamente' });
                }
              } else {
                let linesTotal;
                let item;

                if (cart.id === null) {
                  item = null;
                  linesTotal = 0;
                } else {
                  item = cart.lineas.find((linea) => linea.cod_producto === articledata.sku);
                  linesTotal = cart.lineas.length;
                }

                let allowedByStock;
                if (item) {
                  allowedByStock = (this.totalQuantity + item.cantidad) < ((articledata.Stocks[0].Quantity) * 0.8);
                } else {
                  allowedByStock = this.totalQuantity < ((articledata.Stocks[0].Quantity) * 0.8);
                }
                if (allowedByStock) {
                  let data = { codProducto: this.article.codigo, cantidad: this.totalQuantity };
                  this.cartService.addCart(data).subscribe((response) => {
                    this.cartService.getCart().subscribe(data => {
                      if (data) {
                        let cartIcon = document.getElementById('cart-icon')
                        if (data.lineas.length > 0) {
                          cartIcon.style.visibility = 'visible';
                          cartIcon.innerHTML = data.lineas.length;
                        }
                      }
                    })
                    this.loading = false;
                    this.dialogRef.close('Se han añadido los productos al carrito');
                  })
                } else {
                  this.loading = false;
                  this.toast.danger('Error', 'Cantidad superior al stock disponible. Contacte con el comercial');
                }
              }
            } else {
              this.loading = false;
              this.toast.danger('Error', 'Cantidad superior al stock disponible. Contacte con el comercial');
            }
            this.loading = false;
          })
        });
      } else {
        this.loading = false;
        this.toast.danger('Error', 'El precio supera el riesgo de la cuenta');
      }
    }, error => {
      this.loading = false;
    }
    );
  }

}
