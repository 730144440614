import { Component, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-cookies-dialog',
  templateUrl: './cookies-dialog.component.html',
  styleUrls: ['./cookies-dialog.component.scss']
})
export class CookiesDialogComponent {

  private cookieService = inject(CookieService);
  public cookieActive = false;

  constructor() {
    this.getCookie();
  }

  cookiesAccept() {
    this.setCookie();
  }

  getCookie() {
    this.cookieActive = Boolean(this.cookieService.get('marefix_cookie_consent'));
  }
  setCookie() {
    this.cookieService.set('marefix_cookie_consent', 'true');
    this.getCookie()
  }

}
