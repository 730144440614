import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrdersService } from 'src/app/services/orders.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent  implements OnInit {

  public completeLoaded:boolean = false;
  private clientLoaded:boolean = false;
  private orderLoaded:boolean = false;

  public userData: Object = new Object({
    CompanyName: '',
    Address: '',
    LineAddressCode: '',
    VatNumber: '',
  });

  public order: Object;

  constructor(
    private authenticationService: AuthenticationService,
    private ordersService: OrdersService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.getDataClient();
        this.getInvoiceData(params.id);
      }
    );
  }

  getDataClient() {
    this.authenticationService.getUsersMe().subscribe(
      (data) => {
        console.log(data);
        this.userData['CompanyName'] = data.CompanyName;
        this.userData['Address'] = data.Address;
        this.userData['LineAddressCode'] = data.ZipCode + " - " + data.City + " - " + data.CountryDescription;
        this.userData['VatNumber'] = data.VatNumber;
        this.userData['CommercialAgents'] = data.CommercialAgents;

        this.clientLoaded = true;
        this.checkLoaded();
      },
      (error) => {

      }
    );
  }

  getInvoiceData(idInvoice) {
    this.ordersService.getInvoiceById(idInvoice).subscribe(
      (data)=> {
        console.log(data);
        this.order = data;

        this.orderLoaded = true;
        this.checkLoaded();
      },
      (error) => {
        console.log(error);
      });
  }

  checkLoaded() {
    if(this.clientLoaded == true && this.orderLoaded == true) {
      this.completeLoaded = true;
    }
  }

  getDateFormat(date) {
    const date_convert = new Date(date);

    return date_convert.getDate() + "/" + (date_convert.getMonth()+1) + "/" + date_convert.getFullYear()
  }

  public openPDF(num_order):void {
    let DATA = document.getElementById('document-data');

    html2canvas(DATA).then(canvas => {

        let fileWidth = 208;
        let fileHeight = canvas.height * fileWidth / canvas.width;

        const FILEURI = canvas.toDataURL('image/png')
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)

        PDF.save(num_order+'.pdf');
    });
  }

}
