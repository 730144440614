<div class="content-wrapper">

  <!-- <div id="cursor" class="cursor"></div> -->
  <div>
    <div class="searchbar-div">
      <app-searchbar></app-searchbar>
    </div>
    <div class="" style="background: white; height: fit-content;">
      <app-hero-swiper></app-hero-swiper>
    </div>

    <div id="parallax-wrapper">
      <img id='paralaxRotate' class="parallax-rotate-screws hide-screws"
        src="../../../assets/images/home/svg/screw_decorations.svg" alt="Tornillos decorativos">
      <!-- <div class="container company-info" #parallaxDiv> -->
      <!-- <p class="section-title" id="quienes-somos">Quiénes somos</p> -->
      <!-- <p class="company-info-txt">Somos especialistas en la <span class="bold-font">fabricación</span> y <span
            class="bold-font">distribución </span> de <span class="bold-font">sistemas de
            fijación.</span></p> -->

      <!-- <div class="company-info-txt-small">
                    <p>MAREFIX S.L. es una empresa especializada en la Fabricación y Distribución de sistemas de
                        fijación,
                        dirigida por reconocidos profesionales con más de 40 años de experiencia en el sector.</p>
                    <p class="bold-font" style="text-decoration: underline; padding-top: 20px;"> Compromiso de empresa
                    </p>
                    <p style="padding-top: 20px;">Todas las personas que formamos MAREFIX, estamos comprometidos con
                        nuestros clientes trabajando
                        para
                        ofrecerles la excelencia en todos los aspectos de la empresa, mediante productos de alta calidad
                        con
                        HOMOLOGACIONES y certificaciones, servicio inmediato de 24/48 horas, amplia gama de productos en
                        stock,
                        precios competitivos y unas relaciones fáciles y profesionales con un único objetivo:</p>
                </div> -->
      <!-- </div> -->
      <div class="d-flex flex-column container-images-home blur-w100" style="gap: 70px; margin-top: 120px;">
        <div class="row">
          <div class="col-12 col-md-6 info-text-home" style="font-family: Switzer-Regular;">
            <div>
              <p class="title-text-home">QUIENES SOMOS</p>
              <p style="text-align: justify;"><span style="font-family: Switzer-Bold;">MAREFIX S.L.U.</span> es una
                empresa especializada en la
                <span style="font-family: Switzer-Bold;">Fabricación y
                  Distribución de SISTEMAS DE FIJACIÓN</span>,
                dirigida por reconocidos profesionales con más de 40 años de experiencia en el Sector.
              </p>
              <p style="font-family: Switzer-Regular; text-align: justify;">Ubicados en el Polígono
                Industrial de Lardero,
                a 2 km de Logroño, contamos con unas instalaciones de
                3.500 m2 en las que tenemos a disposición de nuestros clientes 6.000 pallets y más de 5.000 referencias
                para entrega inmediata.</p>
            </div>
            <!-- <p style="text-align: end; font-family: Switzer-Bold;">CONSEGUIR CLIENTES SATISFECHOS.</p> -->
          </div>
          <div class="col-12 col-md-6 p-0">
            <img src="../../../assets/images/home/facade-warehouse/Panorama_FACHADA-2023.jpg" alt=""
              class="image-text-home">
          </div>
          <div class="col-12 col-md-6 p-0">
            <img src="../../../assets/images/home/facade-warehouse/Panorama_ALMACÉN-2023.jpg" alt=""
              class="image-text-home">
          </div>
          <div class="col-12 col-md-6 info-text-home" style="font-family: Switzer-Regular;">
            <div>
              <p class="title-text-home">COMPROMISO DE EMPRESA</p>
              <p style="text-align: justify;">Todas las personas que formamos <span
                  style="font-family: Switzer-Bold;">MAREFIX</span>, estamos
                comprometidos con nuestros clientes trabajando para
                ofrecerles la excelencia en todos los aspectos de la empresa, mediante productos de alta calidad con
                homologaciones y certificaciones, servicio inmediato de 24/48 horas, amplia gama de productos en stock,
                precios competitivos y unas relaciones fáciles y profesionales con un único objetivo: <span
                  style="font-family: Switzer-Bold;">CONSEGUIR CLIENTES
                  SATISFECHOS</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="crafting-quality-wrapper blur-w100">
      <div class="banner">
        <p>SOMOS FABRICANTES</p>
        <img src="../../../assets/images/home/crafting-quality/hojas_bifix-sita.png" alt="">
      </div>
      <div class="d-flex flex-column container-images-home" style="gap: 70px; margin-top: 120px;">
        <div class="row">
          <div class="col-12 col-md-6 p-0 d-flex justify-content-center align-items-center">
            <p class="title">Fabricación y calidad</p>
          </div>
          <div class="col-12 col-md-6 p-0">
            <img src="../../../assets/images/home/crafting-quality/_Operarios G&B-cmyk.jpg" alt=""
              class="image-text-home" style="height: 470px; object-fit:cover">
          </div>
          <div class="col-12 col-md-6 p-0">
            <img src="../../../assets/images/home/crafting-quality/_máquina-azul-cmyk.jpg" alt=""
              class="image-text-home" style="height: 470px; object-fit:cover">
          </div>
          <div class="col-12 col-md-6 info-text-home" style="font-family: Switzer-Regular;">
            <div>
              <p style="text-align: justify;"><span style="font-family: Switzer-Bold;">MAREFIX S.L.U.</span> es una
                empresa especializada en la
                <span style="font-family: Switzer-Bold;">Fabricación y
                  Distribución de SISTEMAS DE FIJACIÓN</span>,
                dirigida por reconocidos profesionales con más de 40 años de experiencia en el Sector.
              </p>
              <p style="font-family: Switzer-Regular; text-align: justify;"><span style="font-family: Switzer-Bold;">G&B
                  FISSAGGI</span> se
                fundó a finales de los años setenta y está <span style="font-family: Switzer-Bold;">ESPECIALIZADA
                  EN LA FABRICACIÓN</span> de fijaciones de nylon y productos químicos. Desde 1980,
                <span style="font-family: Switzer-Bold;">G&B FISSAGGI</span> se dedica al diseño y la fabricación de
                productos de fijación
                en colaboración con el sector de la construcción mediante la distribución
                profesional. Nuestro objetivo es convertirnos en un verdadero socio de nuestros
                clientes para responder a una oferta global en fijación.
                Desde mediados del año 2.022, mediante la fusión con la empresa española
                <span style="font-family: Switzer-Bold;">MAREFIX</span>, ponemos al servicio de nuestros clientes un
                amplio catálogo, con una
                completa gama y soluciones para los diferentes planteamientos de fijación que
                se puedan presentar.
              </p> -->
    <!-- <p class="title-text-home" style="margin-top: 20px; margin-bottom: 20px;">MAREFIX / G&B FISSAGGI, ¿POR
                QUÉ?</p>
              <p>Intentamos crear relaciones fáciles y profesionales. Aunque las exigencias del
                mercado y el nivel de competitividad es cada día mayor, ofrecemos a nuestros
                clientes unas relaciones fluidas y sencillas. Nos resulta natural ponernos en su
                lugar y comprender sus necesidades e inquietudes porque nosotros también
                somos clientes y nuestro objetivo es el mismo, hacer crecer nuestras empresas
                con relaciones profesionales, serias y de calidad</p> -->
    <!-- </div> -->
    <!-- <p style="text-align: end; font-family: Switzer-Bold;">CONSEGUIR CLIENTES SATISFECHOS.</p> -->
    <!-- </div>
          <div class="col-12 col-md-6 p-0 d-flex justify-content-center align-items-center">
            <img src="../../../assets/images/logo/logo.svg" alt="" style="width: 200px;">
          </div>
          <div class="col-12 col-md-6 p-0">
            <img src="../../../assets/images/home/crafting-quality/stampi tassello GL NYLON ENDEREZADO.jpg" alt=""
              class="image-text-home" style="height: 470px; object-fit:cover"> -->
    <!-- <div>
              <p class="title-text-home" style="margin-bottom: 20px;">COMPROMISO DE CALIDAD</p>
              <p>Trabajamos constantemente en ampliar la oferta de productos homologados y
                certificados que cubran las necesidades de nuestros clientes y la demanda del
                mercado.</p>
              <p class="title-text-home" style="margin-top: 20px; margin-bottom: 20px;">COMPROMISO CON EL MEDIO AMBIENTE
              </p>
              <p>"La Tierra no es una herencia de nuestros padres, sino un préstamo de nuestros hijos".
                Estamos comprometidos con el Medio Ambiente y nos preocupamos y ocupamos
                de él, reciclando todos nuestros residuos y cumpliendo las normativas existentes
                en esta materia.</p>
              <p class="title-text-home" style="margin-top: 20px; margin-bottom: 20px;">COMPROMISO DE SERVICIO</p>
              <p>Trabajamos con agencias de transporte que nos garantizan un servicio de 24
                horas a capitales de provincia y ciudades importantes, y 48 horas al resto.
                Estamos en un 98,7% de líneas servidas en albarán y nuestro objetivo es alcanzar
                el 99,8% el presente año.</p>
            </div> -->
    <!-- </div>
        </div>
      </div>
    </div> -->


    <div class="crafting-quality-wrapper blur-w100">
      <div class="banner">
        <p>SOMOS FABRICANTES</p>
        <img src="../../../assets/images/home/crafting-quality/hojas_bifix-sita.png" alt="">
      </div>
      <div class="d-flex flex-column container-images-home" style="gap: 70px; margin-top: 120px;">
        <div class="row">
          <p class="title">Fabricación y calidad</p>

          <div class="col-12 col-xl-6 info-text-home" style="font-family: Switzer-Regular;">
            <div>
              <p style="text-align: justify;"><span style="font-family: Switzer-Bold;">MAREFIX S.L.U.</span> es una
                empresa especializada en la
                <span style="font-family: Switzer-Bold;">Fabricación y
                  Distribución de SISTEMAS DE FIJACIÓN</span>,
                dirigida por reconocidos profesionales con más de 40 años de experiencia en el Sector.
              </p>
              <p style="font-family: Switzer-Regular; text-align: justify;"><span style="font-family: Switzer-Bold;">G&B
                  FISSAGGI</span> se
                fundó a finales de los años setenta y está <span style="font-family: Switzer-Bold;">ESPECIALIZADA
                  EN LA FABRICACIÓN</span> de fijaciones de nylon y productos químicos. Desde 1980,
                <span style="font-family: Switzer-Bold;">G&B FISSAGGI</span> se dedica al diseño y la fabricación de
                productos de fijación
                en colaboración con el sector de la construcción mediante la distribución
                profesional. Nuestro objetivo es convertirnos en un verdadero socio de nuestros
                clientes para responder a una oferta global en fijación.
                Desde mediados del año 2.022, mediante la fusión con la empresa española
                <span style="font-family: Switzer-Bold;">MAREFIX</span>, ponemos al servicio de nuestros clientes un
                amplio catálogo, con una
                completa gama y soluciones para los diferentes planteamientos de fijación que
                se puedan presentar.
              </p>
              <!-- <p class="title-text-home" style="margin-top: 20px; margin-bottom: 20px;">MAREFIX / G&B FISSAGGI, ¿POR
                QUÉ?</p>
              <p>Intentamos crear relaciones fáciles y profesionales. Aunque las exigencias del
                mercado y el nivel de competitividad es cada día mayor, ofrecemos a nuestros
                clientes unas relaciones fluidas y sencillas. Nos resulta natural ponernos en su
                lugar y comprender sus necesidades e inquietudes porque nosotros también
                somos clientes y nuestro objetivo es el mismo, hacer crecer nuestras empresas
                con relaciones profesionales, serias y de calidad</p> -->
            </div>
            <!-- <p style="text-align: end; font-family: Switzer-Bold;">CONSEGUIR CLIENTES SATISFECHOS.</p> -->
          </div>
          <div class="col-12 col-xl-6 p-0 d-flex justify-content-center align-items-center position-relative">
            <img src="../../../assets/images/home/crafting-quality/_Operarios G&B-cmyk.jpg" alt="" class="small-img"
              style=" object-fit:cover">
            <img src="../../../assets/images/home/crafting-quality/_máquina-azul-cmyk.jpg" alt="" class="small-img"
              style=" object-fit:cover">
            <!-- <img src="../../../assets/images/home/crafting-quality/stampi tassello GL NYLON ENDEREZADO.jpg" alt=""
              class="small-img" style=" object-fit:cover"> -->
          </div>

        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap fabrication-imgs blur-w100 justify-content-center">
      <img src=" ../../../assets/images/home/crafting-quality/fabricacion-1.jpg" alt="">
      <img src="../../../assets/images/home/crafting-quality/fabricacion-2.jpg" alt="">
      <img src="../../../assets/images/home/crafting-quality/fabricacion-3.jpg" alt="">
      <img src="../../../assets/images/home/crafting-quality/fabricacion-4.jpg" alt="">
      <img src="../../../assets/images/home/crafting-quality/fabricacion-5.jpg" alt="">

    </div>

    <div class="blur-w100 d-flex justify-content-center align-items-center flex-wrap w-100 logos-wrapper">
      <img src="../../../assets/images/home/stamps/sello-a-plus.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-agua-potable.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-calculation-program.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-ce.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-dielectrics.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-eq1.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-eta-cuadrado.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-eta.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-f90.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-leed-4.1.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-leed.png" alt="">
      <img src="../../../assets/images/home/stamps/sello-r120.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-seismic-area.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-seismic.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-tuv.jpg" alt="">
      <img src="../../../assets/images/home/stamps/sello-ul.png" alt="">
    </div>

    <div class="product-links blur-w100">
      <p class="container section-title">Nuestros productos</p>
      <app-mega-menu-products></app-mega-menu-products>
      <!--
      <div class="home-links">
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link"
            href="/product/a2040e0d-e7a5-4b41-8c1f-af898ff3a0a8?productLineID=a2040e0d-e7a5-4b41-8c1f-af898ff3a0a8">Abrazaderas</a>
        </div>
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link"
            href="/product/218c5278-eccf-42c2-92fd-218f52c60d66?productLineID=218c5278-eccf-42c2-92fd-218f52c60d66">Anclajes
            de nylon</a>
        </div>
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link"
            href="/product/3494deaf-449c-454c-b117-b98f1fc92114?productLineID=3494deaf-449c-454c-b117-b98f1fc92114">Remaches</a>
        </div>
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link"
            href="/product/0ae7b252-b5e2-4afa-9084-d5ac9c915eef?productLineID=0ae7b252-b5e2-4afa-9084-d5ac9c915eef">Soluciones
            expositivas</a>
        </div>
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link"
            href="/product/0ef9f64c-eefe-40b2-933a-fec34b6e9cfc?productLineID=0ef9f64c-eefe-40b2-933a-fec34b6e9cfc">Fijaciones
            de aislamiento</a>
        </div>
        <mat-divider></mat-divider>
        <div class="card-link container"><a class="home-link" href="/product/d1167a42-448f-4408-b0f1-c9446d398580">Tacos
            metálicos</a>
        </div>
        <mat-divider></mat-divider>
      </div>
                 -->
    </div>

    <div class="blur-w100">
      <div class="container cards-hover">
        <p class="section-title">Nuestros compromisos</p>
        <div class="row d-flex align-items-start justify-content-center cards-row">
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/presentacion.svg" alt="">
              </div>
              <p class="card-title">PRESENTACIÓN DE PRODUCTOS</p>
              <p class="card-text-hover">Disponemos de una amplia variedad de envases adaptados para diferentes piezas,
                pesos o necesidades del mercado, tanto industrial como doméstico. </p>
            </div>
          </div>
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover ">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/profesionalidad.svg" alt="">
              </div>
              <p class="card-title">PROFESIONALIDAD</p>
              <p class="card-text-hover">Contamos con personal técnico cualificado para atender debida y eficientemente
                sus consultas.
                S.A.T. Servicio de Asistencia Técnica: +34 941 447825 | sat@marefix.es </p>
            </div>
          </div>
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover ">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/calidad.svg" alt="">
              </div>
              <p class="card-title">COMPROMISO DE CALIDAD</p>
              <p class="card-text-hover">Trabajamos constantemente en ampliar la oferta de productos homologados y
                certificados que cubran las necesidades de nuestros clientes y la demanda del mercado. </p>
            </div>
          </div>
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover ">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/medio_ambiente.svg" alt="">
              </div>
              <p class="card-title">COMPROMISO CON EL MEDIO AMBIENTE</p>
              <p class="card-text-hover">“La Tierra no es una herencia de nuestros padres, sino un préstamo de nuestros
                hijos”.
                Estamos comprometidos con el Medio Ambiente y nos preocupamos y ocupamos de él, reciclando todos
                nuestros residuos y cumpliendo las normativas existentes en esta materia. </p>
            </div>
          </div>
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/porque.svg" alt="">
              </div>
              <p class="card-title">MAREFIX ¿POR QUÉ? </p>
              <p class="card-text-hover">Intentamos crear relaciones fáciles y profesionales. Aunque las exigencias del
                mercado y el nivel de competitividad es cada día mayor, ofrecemos a nuestros clientes unas relaciones
                fluidas y sencillas. Nos resulta natural ponernos en su lugar y comprender
                sus necesidades e inquietudes porque nosotros también somos clientes y nuestro objetivo esel mismo,
                hacer crecer nuestras empresas con relaciones profesionales, serias y de calidad.</p>
            </div>
          </div>
          <div class="col-6  col-lg-4" style="padding-top: 40px;">
            <div class="card-hover ">
              <div class="card-icon">
                <img src="../../../assets/images/home/cards/servicio.svg" alt="">
              </div>
              <p class="card-title">COMPROMISO DE SERVICIO</p>
              <p class="card-text-hover">Trabajamos con agencias de transporte que nos garantizan un servicio de 24
                horas a capitales de provincia y ciudades importantes, y 48 horas al resto.
                Estamos en un 98,7% de líneas servidas en albarán y nuestro objetivo es alcanzar el 99,8% el presente
                año. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>