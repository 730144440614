import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private formHeaders = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    })
  }

  constructor(private http: HttpClient) {
  }

  getCart() {
    const url = `${AppSettings.API_URI}/cart`;

    return this.http.get<any>(
     url, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getPriceSKU(data) {
    const url = `${AppSettings.API_URI}/price`;

    return this.http.post<any>(
     url, data, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getArticleSKU(sku) {
    const url = `${AppSettings.API_URI}/articlebySku?sku=${sku}`;

    return this.http.get<any>(
     url, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  addCart(data) {
    const url = `${AppSettings.API_URI}/addLine`;
    return this.http.post<any>(
     url, data, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  removeLine(id) {
    const url = `${AppSettings.API_URI}/removeLine/${id}`;

    return this.http.delete<any>(
     url, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  editLine(id,data) {
    const url = `${AppSettings.API_URI}/editLine/${id}`;

    return this.http.put<any>(
     url, data, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  createOrder(data) {
    const url = `${AppSettings.API_URI}/CreateOrder`;

    return this.http.post<any>(
     url, data, this.formHeaders
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getVatValue() {
    const vat_table = [['4','IVA 21%','21'],['41','Recargo 5,2%','5,2'],['8','Exento Nacional','0'],['6','Extranjero','0'],['7','UE Ventas (Entregas)','0'],['5','IVA UE Compras 21%','21'],['9','IVA 21% Rect. Hacienda','21'],['90','IVA Exento Rect. Hacienda','0']];

    return vat_table;
  }
}
