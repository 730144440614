import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  now: Date = new Date();
  
  ngAfterViewInit() {
    var links = document.querySelectorAll('.footer-links')
    links.forEach(element => {
      element.addEventListener("mouseenter", function (event) {
        const cursor = document.getElementById('cursor');
        cursor.style.background = 'transparent'
        cursor.style.border = '2px solid white'
        cursor.style.width = '160px'
        cursor.style.height = '160px'
        cursor.style.mixBlendMode = "normal"
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: 'Ver'; font-size: 36px; color: white; text-decoration:underline; mix-blend-mode: difference;}";
      });

      element.addEventListener("mouseout", function (event) {
        const cursor = document.getElementById('cursor');
        cursor.style.background = '#FFFFFF'
        cursor.style.border = 'none'
        cursor.style.scale = "1"
        cursor.style.mixBlendMode = "difference"
        cursor.style.width = '20px'
        cursor.style.height = '20px'
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: none;}";
      });
    });
  }

}
