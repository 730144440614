import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  public breadcrums = new BehaviorSubject<any>({});

  constructor() {

   }

  updateBreadcrums(breadcrumb: any) {
    this.breadcrums.next(breadcrumb);
  }

}
