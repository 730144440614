import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {

  activeLinkIndex = -1;
  navLinks: any[];

  constructor(private router: Router) {
    this.navLinks = [
      {
        label: 'Datos personales',
        link: 'personal-information',
        index: 0
      }, {
        label: 'Direcciones',
        link: 'addresses',
        index: 1
      }, {
        label: 'Pedidos',
        link: 'orders',
        index: 2
      },
    ];
  }

  ngOnInit(): void {

  }

}
