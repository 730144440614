<div class="container content-wrapper">
    <div class="text-wrapper">
        <div class="wpb_wrapper">
            <p class="p1">Este sitio web, usa Cookies para mejorar y optimizar la
                experiencia del usuario. A continuación encontrarás información
                detallada sobre qué son las “Cookies”, qué tipología utiliza este
                sitio web, cómo puedes desactivarlas en tu navegador y cómo bloquear
                específicamente la instalación de Cookies de terceros.</p>
            <h2 class="p2">¿Qué son las Cookies y cómo las utiliza el sitio web de
                MAREFIX, S.L.?</h2>
            <p class="p1">Las Cookies son archivos que el sitio web o la aplicación
                que utilizas instala en tu navegador o en tu dispositivo
                (Smartphone, tableta……) durante tu recorrido por las páginas o por
                la aplicación, y sirven para almacenar información sobre tu visita.
                Como la mayoría de los sitios en internet, el portal web de <span class="s1">MAREFIX, S.L.</span>
                utiliza
                Cookies para:</p>
            <ul class="ul1">
                <li class="li1">Asegurar que las páginas web pueden funcionar
                    correctamente</li>
                <li class="li1">Almacenar sus preferencias, como el idioma que has
                    seleccionado o el tamaño de letra.</li>
                <li class="li1">Conocer tu experiencia de navegación.</li>
                <li class="li1">Recopilar información estadística anónima, como qué
                    páginas has visto o cuánto tiempo has estado en nuestros medios.
                </li>
            </ul>
            <p class="p1">El uso de Cookies nos permite optimizar su navegación,
                adaptando la información y los servicios ofrecidos a tus intereses,
                para proporcionarte una mejor experiencia siempre que nos visites.
                El sitio web de <span class="s1">MAREFIX, S.L.</span> utiliza
                Cookies para funcionar, adaptar y facilitar al máximo la navegación
                del Usuario.</p>
            <p class="p1">Las Cookies se asocian únicamente a un usuario anónimo y
                su ordenador/dispositivo y no proporcionan referencias que permitan
                conocer datos personales. En todo momento podrás acceder a la
                configuración de tu navegador para modificar y/o bloquear la
                instalación de las Cookies enviadas por el sitio web de <span class="s1">MAREFIX, S.L.</span>, sin que
                ello
                impida al acceso a
                los contenidos. Sin embargo, la calidad del funcionamiento de los
                Servicios puede verse afectada.</p>
            <p class="p1">Los Usuarios que completen el proceso de registro o hayan
                iniciado sesión con sus datos de acceso podrán acceder a servicios
                personalizados y adaptados a sus preferencias según la información
                personal suministrada en el momento del registro y la almacenada en
                la Cookie de su navegador.</p>
            <ul class="ul1">
                <li class="li5">Las cookies de autenticación son por defecto cookies
                    temporales (por sesiones) no persistentes, sin embargo, los
                    usuarios pueden hacerla persistente marcando la casilla
                    «Remember login» en el control de acceso. Para usuarios
                    registrados.</li>
            </ul>
            <ul class="ul1">
                <li class="li5">Las cookies de sesión, que se producen al loguearse
                    tienen una duración de 30 minutos desde último período de
                    actividad. Para usuarios registrados.</li>
            </ul>
            <ul class="ul1">
                <li class="li5">Se crea una cookie llamada «lenguaje» para almacenar
                    el lenguaje &#8211; en una instalación monolingües esto es
                    simplemente el idioma por defecto del navegador, pero si el
                    sitio es compatible con varios idiomas, entonces puede ser
                    diferente según el idioma seleccionado. Para todo tipos de
                    usuarios.</li>
            </ul>
            <h2>Cookies utilizadas por este sitio web</h2>
            <table border="0" width="100%" cellspacing="0" cellpadding="0">
                <tbody>
                    <tr>
                        <td bgcolor="#E4E4E4">TITULAR</td>
                        <td bgcolor="#E4E4E4">NOMBRE DE LA COOKIE</td>
                        <td bgcolor="#E4E4E4">FUNCIÓN</td>
                    </tr>
                    <tr>
                        <td>WPML</td>
                        <td>_icl_current_language<br />
                            wpml_browser_redirect_test</td>
                        <td>Permiten identificar el idioma del usuario a fin de
                            mostrarle la página web en el idioma correcto.</td>
                    </tr>
                    <tr>
                        <td>Wordfence</td>
                        <td>wf_loginalerted</td>
                        <td>Cookie de seguridad que impide la intrusión e identifica
                            visitantes no lícitos. Guarda datos sobre el inicio de
                            sesión para la administración del sitio web.</td>
                    </tr>
                    <tr>
                        <td>Marefix.com</td>
                        <td>cookie_notice_acepted</td>
                        <td>Se usa para recordar que el usuario ha aceptado la
                            política de cookies y no volver a mostrarle el aviso.
                        </td>
                    </tr>
                </tbody>
            </table>
            <h2>¿Cómo puedo configurar mis Cookies?</h2>
            <ul class="ul1">
                <li class="li1">Al navegar y continuar en nuestro Sitio Web estará
                    consintiendo el uso de las Cookies en las condiciones contenidas
                    en la presente Política de <span class="s1">Cookies. MAREFIX,
                        S.L.</span> proporciona acceso a esta Política de Cookies en
                    el momento del registro con el objetivo de que el usuario esté
                    informado, y sin perjuicio de que éste pueda ejercer su derecho
                    a bloquear, eliminar y rechazar el uso de Cookies en todo
                    momento.</li>
                <li class="li1">En cualquier caso le informamos de que, dado que las
                    Cookies no son necesarias para el uso de nuestro Sitio Web,
                    puede bloquearlas o deshabilitarlas activando la configuración
                    de su navegador, que le permite rechazar la instalación de todas
                    las cookies o de algunas de ellas. La práctica mayoría de los
                    navegadores permiten advertir de la presencia de Cookies o
                    rechazarlas automáticamente. Si las rechaza podrá seguir usando
                    nuestro Sitio Web, aunque el uso de algunos de sus servicios
                    podrá ser limitado y por tanto su experiencia en nuestro Sitio
                    Web menos satisfactoria.</li>
            </ul>
            <h2 class="p7">¿Cómo puedo bloquear o permitir las cookies?</h2>
            <p class="p8">El usuario podrá -en cualquier momento- elegir qué cookies
                quiere que funcionen en este sitio web mediante:</p>
            <ul class="ul1">
                <li class="li8">la configuración del <b>navegador</b>; por ejemplo:
                    <ul class="ul1">
                        <li class="li9"><span class="s1"><b>Chrome</b>, desde <a
                                    href="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647%22%20%5Ct%20%22_blank"><span
                                        class="s5">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</span></a></span>
                        </li>
                        <li class="li9"><span class="s1"><b>Explorer</b>, desde <a
                                    href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9%22%20%5Ct%20%22_blank"><span
                                        class="s5">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</span></a></span>
                        </li>
                        <li class="li9"><span class="s1"><b>Firefox</b>, desde <a
                                    href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we%22%20%5Ct%20%22_blank"><span
                                        class="s5">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</span></a></span>
                        </li>
                        <li class="li9"><span class="s1"><b>Safari</b>, desde <a
                                    href="http://support.apple.com/kb/ph5042%22%20%5Ct%20%22_blank"><span
                                        class="s5">http://support.apple.com/kb/ph5042</span></a></span>
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>Existen herramientas de terceros, disponibles on line, que
                    permiten a los usuarios detectar las cookies en cada sitio web
                    que visita y gestionar su desactivación.</li>
            </ul>
        </div>
    </div>
</div>