import { Component } from '@angular/core';
import { OrdersService } from '../../../services/orders.service';
import { faDownload, faEye } from '@fortawesome/pro-regular-svg-icons';
import { DocumentService } from '../../../services/documents.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {
  start_date: any = null;
  end_date: any = null;
  faDownload = faDownload;
  faEye = faEye;

  date_complete: boolean = false;

  orders: [] = [];
  start_date_aux: any;
  end_date_aux: any;
  public download: boolean = false;

  constructor(
    private ordersService: OrdersService,
    private documentService: DocumentService,
    public spinner: NgxSpinnerService
  ) {
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: true,
      bdColor: "rgba(0, 0, 0, 0.3)",
    });

    this.setInitialDates();
  }

  setInitialDates() {
    this.end_date_aux = new Date();
    this.start_date_aux = new Date();
    this.start_date_aux.setMonth(this.start_date_aux.getMonth() - 1);

    this.end_date = { year: this.end_date_aux.getFullYear(), month: this.end_date_aux.getMonth() + 1, date: this.end_date_aux.getDate() };
    this.start_date = { year: this.start_date_aux.getFullYear(), month: this.start_date_aux.getMonth() + 1, date: this.start_date_aux.getDate() };

    this.date_complete = true;
    this.getOrders(
      1,
      this.start_date.year + "-" + this.start_date.month + "-" + this.start_date.date,
      this.end_date.year + "-" + this.end_date.month + "-" + this.end_date.date
    );
  }

  startDataChange(event) {
    this.date_complete = false;
    this.start_date = event.value._i;


    if (this.start_date !== null && this.end_date !== null) {
      this.date_complete = true;

      this.getOrders(
        1,
        this.start_date.year + "-" + this.start_date.month + "-" + this.start_date.date,
        this.end_date.year + "-" + this.end_date.month + "-" + this.end_date.date
      );
    }
  }

  endDataChange(event) {
    this.end_date = event.value._i;


    if (this.start_date !== null && this.end_date !== null) {
      this.date_complete = true;
      this.getOrders(
        1,
        this.start_date.year + "-" + (this.start_date.month + 1) + "-" + this.start_date.date,
        this.end_date.year + "-" + (this.end_date.month + 1) + "-" + this.end_date.date
      );
    }
  }

  getOrders(page, date_from, date_to) {
    this.ordersService.getOrderByCustomer(page, date_from, date_to).subscribe(
      (data) => {
        console.log(data);
        this.orders = data.Items;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getDateFormat(date) {
    const date_convert = new Date(date);

    return date_convert;

    return date_convert.getDate() + "/" + (date_convert.getMonth() + 1) + "/" + date_convert.getFullYear()
  }

  downloadPDF(id, codinv) {
    this.download = true;
    this.documentService.getOrder(id).subscribe(
      (data) => {
        var fileName = codinv + '.pdf';
        saveAs(data, fileName);
        this.download = false;
      }
    );
  }

  vieDocument(id) {
    window.open('document/order?id=' + id, "_blank");
  }
}
