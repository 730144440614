// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //backend: "http://127.0.0.1:8000/api",
  //login: "http://127.0.0.1:8000",
  //assets: "http://127.0.0.1:8000/img/",
  // backend: "https://api.marefix.sdi.es/api",
  // login: "https://api.marefix.sdi.es",
  // assets: "https://api.marefix.sdi.es/img/",
  backend: "https://api.marefix.com/api",
  login: "https://api.marefix.com",
  assets: "https://api.marefix.com/img/",
  debug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
