<div class="add-to-cart-wrapper" style="cursor: default;">
  <h3>{{titleDialog}}</h3>
  <div class="select-options d-flex">
    <div class="input-wrapper d-flex align-items-center">
      <div>
        <p>Formato:</p>
        <mat-form-field class="select-size">
          <div class="align-items-center d-flex">
            <mat-select [(ngModel)]="default" (selectionChange)="changeSize($event)" panelClass="panelfilter">
              <mat-option *ngFor="let envase of envases" [value]="envase">{{envase}} UDS.</mat-option>
            </mat-select>
            <mat-select-trigger matSuffix style="vertical-align: middle;">
              <i class="fal fa-angle-down"></i>
            </mat-select-trigger>
          </div>
        </mat-form-field>
      </div>
      <div>
        <p>Cantidad total:</p>
        <mat-form-field>
          <input min="1" matInput type="number" id="quantity" name="quantity" (ngModelChange)="changeQuantity($event)"
            required placeholder="Cantidad" [(ngModel)]="totalQuantity">
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="totals">
    <div class="totals-wrapper d-flex align-items-center">
      <h3>Cantidad: </h3>
      <p>{{quantity}}</p>
    </div>
    <div class="totals-wrapper d-flex">
      <h3>Precio total: </h3>
      <p>{{totalPrice}}</p>
      <ngx-skeleton-loader *ngIf="loadedPrice" style="margin-bottom: 0px !important;" class="align-items-center"
        [theme]="{ width: '80px', 'border-radius': '0', height: '15px', 'margin-bottom': '0px' }"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="button-wrapper d-flex justify-content-end ">
    <div class="btn-secondary" (click)="addLine()" [class]="loading==true ? 'disabled' : ''">
      <mat-spinner *ngIf="loading==true"></mat-spinner>
      <span *ngIf="loading==false">{{buttonDialog}}</span>
    </div>
  </div>


</div>