<div class="megamenu-wrapper">
  <div class="menu-content container d-flex justify-content-center align-items-center p-0">
    <div class="row" style="width: 100%;">
      <div class="col-3 " *ngFor="let menuItem of menuItemsArray" (click)="showFamily(menuItem)">
        <div class="menu-item-wrapper d-flex align-items-center">
          <div class="menu-item-icon"> <img [src]='"../../../../assets/images/megamenu/"+menuItem.icon'
              onerror="this.onerror=null; this.src='../../../../assets/images/megamenu/icon_1.svg'" alt=""></div>
          <div style="text-transform: capitalize;" class="menu-item-name">{{menuItem.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>