import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductSearchService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getProductSearch(search: string = '', page: number = 1, items: number = 50, lineID: string = '', familyID: string = '') {
    let params = {
      search: search,
      page: page,
      items: items,
    }
    if (lineID != '') {
      params[lineID] = lineID
    }
    if (search != '') {
      params[search] = search
    }
    if (familyID != '') {
      params[familyID] = familyID
    }

    return this.http.get<any>(
      `${AppSettings.API_URI}/productSubFamilySearch`,
      {
        headers: this.reqHeader,
        params: params
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}


