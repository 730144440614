<div class="wrapper-custom fixed-top">
  <nav class="header navbar navbar-expand-xl  nav-expand headerScroll">
    <div class="header-wrapper container container-text-white container-fluid">
      <div class="navbar-header">
        <div class="logo logo-wrapper">
          <div class="logo" (click)="openMegamenu(false)" [routerLink]="['/']">
            <img #imgHeader src="../../../../assets/images/logo/logo.svg" alt="Marefix logo">
          </div>
        </div>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0 d-flex align-items-center">
          <li class="nav-item hide-megamenu px-5" (mouseover)="openMegamenu(false)">
            <a class="nav-link " aria-current="page" href="#quienes-somos" (click)="openMegamenu(false)">Empresa</a>
          </li>
          <li #menuItemProducts id="menuItemProducts" class="nav-item px-5" style="cursor: pointer;">
            <span class="nav-link" (click)="openMegamenu(!openedMegamenu)">Productos</span>
          </li>
          <!-- <li class="nav-item nav-item hide-megamenu px-5">
            <a class="nav-link" href="#">Contacto</a>
          </li> -->
          <li class="nav-item nav-item hide-megamenu px-5" (mouseover)="openMegamenu(false)">
            <a class="nav-link" target="_blank" (click)="openMegamenu(false)"
              href="assets/catalogo-marefix-2023.pdf">Catálogo</a>
          </li>
          <li class="nav-item hide-megamenu px-5" (mouseover)="openMegamenu(false)">
            <a class="nav-link " aria-current="page" (click)="goToLogin(); openMegamenu(false)"
              style="cursor: pointer;">Área privada</a>
          </li>
        </ul>
        <div class="nav-icons-wrapper d-flex align-items-center justify-content-between">
          <a class="nav-link"><i class="fa-light fa-magnifying-glass" (click)="goToSearch()"></i></a>
          <!-- <a class="nav-link"><i class="fa-light fa-circle-user" (click)="goToLogin()"></i></a> -->
          <a class="nav-link"><i class="fa-light fa-cart-shopping" style="margin: 0;" (click)="goToCart()"></i>
            <div id="cart-icon" class="badge-icon" (click)="goToCart()">1</div>
          </a>
        </div>
      </div>
    </div>
  </nav>

  <div #megamenu id="megamenu" class="megamenu-expanded fadeInAnimation">
    <app-mega-menu-products (closeMegamenu)="openMegamenu(false)"></app-mega-menu-products>
  </div>
</div>