import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { ProductFamilyService } from 'src/app/services/product-family.service';
import { ProductLineService } from 'src/app/services/product-line.service';
import { ProductSubfamilyService } from 'src/app/services/product-subfamily.service';

@Component({
  selector: 'app-sidebar-product-filter',
  templateUrl: './sidebar-product-filter.component.html',
  styleUrls: ['./sidebar-product-filter.component.scss']
})
export class SidebarProductFilterComponent implements OnInit {
  public lines: any[];
  public familys: any[];
  public subFamilys: any[] = [];
  public selectedFamily: string;

  @Output() changeRoute: EventEmitter<any> = new EventEmitter();

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private productLineService: ProductLineService, private productFamilyService: ProductFamilyService, private productSubfamily: ProductSubfamilyService, private breadcrumbsService: BreadcrumbsService) {
    this.productLineService.getProductLines().subscribe(data => {
      if (data?.Items) {
        this.lines = data.Items;
      }
    })
    this.familys = []
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      if (params.idLine) {
        this.selectedFamily = params.productLineId;
        this.showedSubfamilys = true;
        this.showFamily({ ProductLineID: params.idLine });
      }
    });
    this.showedSubfamilys = true;

  }

  public showedSubfamilys: boolean = false;

  showFamily(line: any) {
    if (line.ProductLineID === this.selectedFamily) {
      this.selectedFamily = undefined;
      return;
    }
    this.familys = [];
    this.selectedFamily = line.ProductLineID;
    this.showedSubfamilys = true;
    this.getProducts();
  }

  getProducts() {
    this.productFamilyService.getProductFamily(this.selectedFamily).subscribe(data => {
      if (data.length > 0) {
        this.familys = data;
      }
    })
  }

  getLineProduct(family: any) {
    this.changeRoute.emit({load: true});

    this.router.navigate(['product/' + family.ProductLineID],
      { queryParams: { productLineId: family.ProductLineID} }
    );
  }

  showSubfamily(subamily: any) {
    // let breadcrumbs = this.breadcrumbsService.breadcrums.getValue();

    // breadcrumbs.family = { name: family.ProductFamilyDescription, id: family.ProductFamilyID }
    // this.breadcrumbsService.updateBreadcrums(breadcrumbs)
    // this.router.navigate(['product/' + family.ProductLineID + '/' + family.ProductFamilyID],
    //   { queryParams: { productLineId: family.ProductLineID, productFamilyId: family.ProductFamilyID } }
    // );

    this.changeRoute.emit({ productLineId: subamily.ProductLineID, productFamilyId: subamily.ProductFamilyID });

    if (this.selectedFamily !== subamily.ProductLineID) {
      this.showFamily({ ProductLineID: subamily.ProductLineID });
      this.showedSubfamilys = true;
    }
  }

}
