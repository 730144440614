import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {
  initial_load_data: boolean = false;
  user_data: any = null;

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.getUserMe();
  }

  getUserMe() {
    this.authService.getUsersMe().subscribe(
      (data) => {
        console.log(data);
        this.user_data = data;
        this.initial_load_data = true;
      },
      (error) => {
        console.log(error);
      }
    )
  }

  processEmail(email) {
    if (email.includes(',')) {
      return email.split(',');
    }

    return [email];
  }

  logout() {
    let element = document.getElementById('cart-icon')
    element.style.visibility = 'hidden'
    element.innerHTML = '0';
    localStorage.removeItem('jwt_token');
    this.router.navigate(['/']);
  }

}
