import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';
import { SidebarProductFilterComponent } from 'src/app/components/sidebar-product-filter/sidebar-product-filter.component';
import { ProductFamilyService } from 'src/app/services/product-family.service';
import { ProductSearchService } from 'src/app/services/product-search.service';
import { ProductSubfamilyService } from 'src/app/services/product-subfamily.service';

@Component({
  selector: 'app-products-categories',
  templateUrl: './products-categories.component.html',
  styleUrls: ['./products-categories.component.scss'],
  providers: [SidebarProductFilterComponent]
})
export class ProductsCategoriesComponent implements OnInit {
  public familysBreadcrums = [];

  productsArray: any[] = [];
  imgPath: string;
  searchActive = false;

  productLine: string;
  productFamily: string;
  loaded: boolean = false;

  querySearch: string = "";

  @ViewChild('inputSearch') input: ElementRef;

  constructor(private productSearchService: ProductSearchService, private spinner: NgxSpinnerService, private router: Router, private activatedRoute: ActivatedRoute, private productFamilyService: ProductFamilyService, private productSubfamilyService: ProductSubfamilyService, private sideMenu: SidebarProductFilterComponent) { }

  ngOnInit(): void {
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: false,
      bdColor: "rgba(0, 0, 0, 0)",
    });

    this.activatedRoute.params.subscribe((resp) => {
      this.productLine = resp?.idLine
      this.productFamily = resp?.idFamily
      if (this.productLine && this.productFamily) {
        this.productSubfamilyService.getProductSubfamilyByFamily(this.productLine, this.productFamily).subscribe((data) => {
          this.productsArray = data.items;
          this.loaded = true;
        })
      }
      else if (this.productLine && !this.productFamily) {
        this.productSubfamilyService.getProductSubfamilysByLine(this.productLine).subscribe((data) => {
          this.productsArray = data.items;
          this.loaded = true;
        })
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params?.search) {
        this.productSearchService.getProductSearch(params.search).subscribe(data => {
          if (data) {
            this.productsArray = data.items;
            this.loaded = true;
          }
        })
      }
      else {
        if (!this.productLine && !this.productFamily) {
          this.productSearchService.getProductSearch().subscribe(data => {
            if (data) {
              this.productsArray = data.items;
              this.loaded = true;
            }
          })
        }
      }

    });

  }

  getProducts() {

  }

  ngAfterViewInit(): void {
    var cards = document.querySelectorAll('.product-mouse-effect')
    cards.forEach(element => {
      element.addEventListener("mouseover", function (event) {
        const cursor = document.getElementById('cursor');
        cursor.style.background = 'transparent'
        cursor.style.border = '2px solid #82bb27'
        cursor.style.width = '160px'
        cursor.style.height = '160px'
        cursor.style.mixBlendMode = "normal"
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: 'Ver'; font-size: 36px; color: #82bb27; text-decoration:underline; mix-blend-mode: difference;}";
      });

      element.addEventListener("mouseout", function (event) {
        var eventTarget = <Node>event.target;
        var parentNode = <Node>element;
        if (parentNode.contains(eventTarget)) {
          event.preventDefault()
        }
        const cursor = document.getElementById('cursor');
        cursor.style.background = '#FFFFFF'
        cursor.style.border = 'none'
        cursor.style.scale = "1"
        cursor.style.mixBlendMode = "difference"
        cursor.style.width = '20px'
        cursor.style.height = '20px'
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: none;}";
      });
    });

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((text) => {
          this.search();
        })
      )
      .subscribe();
  }

  viewProduct(product) {
    this.router.navigate(['product/' + product.ProductLineID + '/' + product.ProductFamilyID],
      { queryParams: { productLineId: product.ProductLineID, productFamilyId: product.ProductFamilyID } }
    ).then(() => {
      this.router.navigate(['product/' + product.ProductLineID + '/' + product.ProductFamilyID + '/' + product.ProductSubFamilyID],
        { queryParams: { subfamilyCode: product.ProductSubFamilyCode, subfamilyDescription: product.ProductSubFamilyDescription.split(',')[product.ProductSubFamilyDescription.split(',').length - 1] } });
    });
  }

  search() {
    this.searchActive = true;
    this.productsArray = [];
    this.loaded = false;
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: false,
      bdColor: "rgba(0, 0, 0, 0)",
    });
    this.productSearchService.getProductSearch(this.querySearch).subscribe(data => {
      if (data) {
        this.productsArray = data.items;
        this.loaded = true;
      }
    })
  }

  changeRoute(event: any) {
    this.loaded = false;
    if (event?.load) {
      return;
    }
    this.productLine = event?.productLineId;
    this.productFamily = event?.productFamilyId;
    this.productsArray = [];
    this.productSubfamilyService.getProductSubfamilyByFamily(this.productLine, this.productFamily).subscribe((data) => {
      this.productsArray = data.items;
      this.loaded = true;
    })
  }

}
