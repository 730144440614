import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';
import { CartService } from 'src/app/services/cart.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  message = "";
  showingPassword: boolean = false;

  constructor(
    private titleService: Title,
    private elementRef: ElementRef,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private cartService: CartService
  ) {
    this.titleService.setTitle('Login');
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  login() {
    if (this.loginForm.invalid) {
      this.message = 'Rellena todos los campos';
      return;
    }
    this.authenticationService.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
      .pipe(first())
      .subscribe(
        data => {
          if (data.token !== '') {
            this.router.navigateByUrl('/profile');
            this.cartService.getCart().subscribe(data => {
              if (data) {
                let cartIcon = document.getElementById('cart-icon')
                if (data.lineas.length > 0) {
                  cartIcon.style.visibility = 'visible';
                  cartIcon.innerHTML = data.lineas.length;
                }
              }
            })
            return true;
          } else {
            this.message = 'No has podido acceder, vuelve a intentarlo de nuevo';
            console.log('No has podido acceder, vuelve a intentarlo de nuevo');
          }
        },
        error => {
          this.message = 'Credenciales inválidas';
          console.log('ERROR');
        }
      );

  }
  showPassword() {
    let aux = this.showingPassword;
    this.showingPassword = !aux;
  }
}
