import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { ProductLineService } from 'src/app/services/product-line.service';

@Component({
  selector: 'app-mega-menu-products',
  templateUrl: './mega-menu-products.component.html',
  styleUrls: ['./mega-menu-products.component.scss']
})
export class MegaMenuProductsComponent implements OnInit {

  menuItem: any = { id: 1, name: 'Fijaciones metálicas', icon: 'icon_1.svg' }
  menuItemsArray: any[];
  @Output() closeMegamenu: EventEmitter<any> = new EventEmitter();

  constructor(private productLineService: ProductLineService, private router: Router, private breadcrumbsService: BreadcrumbsService) {
    this.menuItemsArray = [];

  }

  ngOnInit(): void {
    this.productLineService.getProductLines().subscribe((data) => {
      if (data?.Items) {
        data.Items.forEach(item => {
          this.menuItemsArray.push({ id: item.ProductLineID, name: String(item.ProductLineDescription).toLocaleLowerCase(), icon: item.$id + '.svg' })
        })
      }
    })
  }

  showFamily(menuItem) {
    var megamenu = document.getElementById('megamenu')
    megamenu.style.display = 'none';
    this.closeMegamenu.emit('close');
    let breadcrumbs = this.breadcrumbsService.breadcrums.getValue();
    breadcrumbs.line = { name: menuItem.name, id: menuItem.id }
    this.breadcrumbsService.updateBreadcrums(breadcrumbs)
    this.router.navigate(['product/' + menuItem.id]);
  }
}
