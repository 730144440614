import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public user: any = false;

  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    const formHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(AppSettings.API_LOGIN + '/login', { username: username, password: password }, formHeaders).pipe(
      map(jwt => {
        if (jwt.token) {
          localStorage.setItem('jwt_token', jwt.token);
        }

        return jwt;
      })
    );
  }

  logout() {
    localStorage.removeItem('jwt_token');
    location.reload();
  }

  getUsersMe() {
    const url = `${AppSettings.API_URI}/me`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        return data;
      })
    );
  }

  check() {
    const url = `${AppSettings.API_URI}/me`;
    return this.http.get<any>(url).pipe(
      map((data) => {
        if(data.code == 200) {
          return true;
        }

        return false;
      })
    );
  }
}
