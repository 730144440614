<div class="profile-wrapper">
    <div class="container p-0">

        <!-- <nav mat-tab-nav-bar [tabPanel]="tabPanel">
                <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive
                    #rla="routerLinkActive" [active]="rla.isActive"   (click)="activeLink = link"
                    [active]="activeLink == link">
                    {{link.label}}
                </a>
            </nav>
            <mat-tab-nav-panel #tabPanel>
                <router-outlet></router-outlet>
            </mat-tab-nav-panel> -->
        <div class="tabs-profile">
            <mat-tab-group>
                <mat-tab label="Pedidos">
                    <app-orders></app-orders>
                </mat-tab>
                <mat-tab label="Albaranes">
                    <app-documents></app-documents>
                </mat-tab>
                <mat-tab label="Facturas">
                    <app-reservations></app-reservations>
                </mat-tab>
                <mat-tab label="Direcciones">
                    <app-addresses></app-addresses>
                </mat-tab>
                <mat-tab label="Mi cuenta">
                    <app-personal-data></app-personal-data>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>