import { Platform } from '@angular/cdk/platform';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductLineService } from 'src/app/services/product-line.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @HostListener('window:scroll', ['$event'])


  onScroll() {
    if (screen.width>1000) {
      const image = document.getElementById("paralaxRotate");
      const parallaxDiv = document.getElementById("parallax-wrapper");
      var height = parallaxDiv.getBoundingClientRect().top;


      if (height < -50) {
        image.style.position = "fixed"
        image.style.transform = "rotate(" + window.pageYOffset / 10 + "deg)";
      } else {
        image.style.position = "absolute"
      }
    }
  }

  count: number;
  hideScrews: boolean;

  constructor(private router: Router, private platform: Platform) { }

  ngOnInit(): void {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.hideScrews = true;
    }
  }

  ngAfterViewInit() {

    //TODO Cambiar el mix-blend-mode de los elementos para que sea el background quien lo tenga  https://codepen.io/AndrewKnife/pen/XWzBpeN

    var links = document.querySelectorAll('.home-link')
    links.forEach(element => {
      element.addEventListener("mouseenter", function (event) {
        const cursor = document.getElementById('cursor');
        cursor.style.background = 'transparent'
        cursor.style.border = '2px solid #262020'
        cursor.style.width = '160px'
        cursor.style.height = '160px'
        cursor.style.mixBlendMode = "normal"
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: 'Ver'; font-size: 36px; color: #262020; text-decoration:underline; mix-blend-mode: difference;}";
      });

      element.addEventListener("mouseout", function (event) {
        const cursor = document.getElementById('cursor');
        cursor.style.background = '#FFFFFF'
        cursor.style.border = 'none'
        cursor.style.scale = "1"
        cursor.style.mixBlendMode = "difference"
        cursor.style.width = '20px'
        cursor.style.height = '20px'
        var styleElem = document.head.appendChild(document.createElement("style"));
        styleElem.innerHTML = "#cursor:before {content: none;}";
      });
    });
  }

  logout() {
    localStorage.removeItem('jwt_token');
    this.router.navigate(['/']);
  }

  navigateLink(url) {
    console.log(decodeURI(url));
    this.router.navigate([decodeURI(url)]);
  }

}


