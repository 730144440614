<div class="product-line-wrapper">
  <div class="icon-wrapper d-flex justify-content-start">
    <img [src]='"../../../../assets/images/megamenu/"+menuItem.id+".svg"'
      onerror="this.onerror=null; this.src='../../../../assets/images/megamenu/icon_1.svg'" alt="">
  </div>
  <div class="product-card-info ">
    <div class="product-name">
      <p style="text-transform: capitalize;">{{menuItem.name}}</p>
    </div>
  </div>
  <div class="see-more-link ">
    <!-- <span *ngIf="!isLogged(); else userlogged">Leer más <i class="fa-regular fa-chevron-right"></i></span> -->
    <ng-template #userlogged>
      <span>Comprar <i class="fa-regular fa-chevron-right"></i></span>
    </ng-template>
  </div>
</div>