<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="button-wrapper">
        <div (click)="createAddress()" class="btn-secondary button-new-address">Nueva dirección</div>
      </div>
    </div>
  </div>
  <div *ngFor="let address of addresses; index as i;" class="user-data-card">
      <div class="profile-info">
          <div class="row">
            <div class="col-md-8">
              <p class="profile-title">Información</p>
            </div>
            <div class="col-md-4 icon-adrress-row">
              <fa-icon (click)="editAddress(i)" class="icon-address-edit" [icon]="faPenToSquare"></fa-icon>
              <fa-icon (click)="deleteAddress(address.id)"class="icon-address-trash" [icon]="faTrash"></fa-icon>
            </div>
          </div>
          <div class="row">
              <div class="col-md-6 col-sm-12">
                  <div class="row d-flex align-items-center">
                      <div class="col-4">
                          <p class="info-label">Alias</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.alias}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row">
                      <div class="col-4">
                          <p class="info-label">Compañía</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.company}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row d-flex align-items-center">
                      <div class="col-4">
                          <p class="info-label">Nombre</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.first_name}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row">
                      <div class="col-4">
                          <p class="info-label">Apellido</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.last_name}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row d-flex align-items-center">
                      <div class="col-4">
                          <p class="info-label">Dirección 1</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.address1}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row">
                      <div class="col-4">
                          <p class="info-label">Dirección 2</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.address2}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row d-flex align-items-center">
                      <div class="col-4">
                          <p class="info-label">Ciudad</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.city}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                  <div class="row d-flex align-items-center">
                      <div class="col-4">
                          <p class="info-label">Comunidad autónoma</p>
                      </div>
                      <div class="col-8">
                          <p class="info-data">{{address.state}}</p>
                      </div>
                  </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-4">
                        <p class="info-label">Código Postal</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data">{{address.postCode}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-4">
                        <p class="info-label">País</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data">{{address.country}}</p>
                    </div>
                </div>
            </div>
          </div>
      </div>
  </div>
</div>
