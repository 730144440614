import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getOrderByCustomer(page: number, date_from: string, date_to: string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/OrderRPSbyCustomer`, {
        headers: this.reqHeader,
        params: {
          page: page,
          items: 15,
          dateFrom: date_from,
          dateTo: date_to
        }
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getInvoicesByCustomer(page: number, date_from: string, date_to: string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/GetInvoiceRPSs`, {
        headers: this.reqHeader,
        params: {
          page: page,
          items: 15,
          dateFrom: date_from,
          dateTo: date_to
        }
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getDeliveryByCustomer(page: number, date_from: string, date_to: string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/GetDeliveryRPSs`, {
        headers: this.reqHeader,
        params: {
          page: page,
          items: 15,
          dateFrom: date_from,
          dateTo: date_to
        }
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getOrderById(orderId) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/OrderRPSbyId/${orderId}`, {
        headers: this.reqHeader
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getInvoiceById(orderId) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/GetInvoiceRPSId/${orderId}`, {
        headers: this.reqHeader
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }

  getDeliveryById(orderId) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/GetDeliveryRPSId/${orderId}`, {
        headers: this.reqHeader
      }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}
