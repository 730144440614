import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { AddToCartComponent } from 'src/app/components/add-to-cart/add-to-cart.component';
import { ArticlesService } from 'src/app/services/articles.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileService } from '../../services/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from '../../services/cart.service';
import { ToastEvokeService, ConfirmBoxEvokeService } from '@costlydeveloper/ngx-awesome-popup';
import { error } from 'console';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  public faPenToSquare = faPenToSquare;
  public faTrash = faTrash;

  public cartItems = [];
  public totalPriceItems = 0.0;
  public portesPrice = 0.0;
  public loadCart = true;
  public vatPercent = null;
  public vatPercent2 = null;

  public addresses = [];

  public idAddressCart = null;

  public emptyCart = false;

  private completeOrder: any;

  public cardAddress = {
    name: "",
    adress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    company: ""
  }

  imgPath = AppSettings.API_ASSETS;

  constructor(
    private cartService: CartService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private articlesService: ArticlesService,
    private profileService: ProfileService,
    private toastEvokeService: ToastEvokeService,
    private confirmBoxEvokeService: ConfirmBoxEvokeService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.updateCartLines();

    this.profileService.getAdresses().subscribe(data => {
      this.addresses = data;
      if (this.addresses.length > 0) {
        this.idAddressCart = this.addresses[0].id;
        this.selectAddress(this.idAddressCart);
      }
    })
  }

  endCart() {
    if (this.totalPriceItems < 100) {
      this.confirmBoxEvokeService.danger('Atención', 'No se permiten pedidos inferiores a 100€', 'Ok').subscribe(resp => {
      })
      return 0;
    }
    if (this.cartItems.length == 0) {
      this.toastEvokeService.danger('Información', 'Es necesario añadir algún producto al carrito').subscribe();
      return 0;
    }

    if (this.idAddressCart == null) {
      this.toastEvokeService.danger('Información', 'Tienes que seleccionar una dirección de entrega').subscribe();
      return 0;
    }

    this.profileService.getAddressId(this.idAddressCart).subscribe(
      (data) => {
        const lines = [];
        const address = {
          $id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          company: data.company,
          address_1: data.address1,
          address_2: data.address2,
          city: data.city,
          state: data.state,
          postcode: data.postCode,
          country: data.country,
        }

        this.cartItems.forEach(
          (element) => {
            lines.push(
              {
                codarticle: element.cod_producto,
                quantity: element.cantidad
              }
            );
          }
        );

        if (this.portesPrice === 9.8) {
          lines.push(
            {
              codarticle: 'P00001',
              quantity: 1,
              price: 9.8
            }
          );
        }


        if (this.portesPrice === 25.0) {
          lines.push(
            {
              codarticle: 'P00001',
              quantity: 1,
              price: 25.0
            }
          );
        }

        const date = new Date();
        const date_order = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

        const full_order = {
          order: {
            orderDate: date_order,
            line_items: lines,
            shipping_address: address,
            errors: null
          },
          total: this.totalPriceItems
        }

        this.cartService.createOrder(full_order).subscribe(
          (data) => {
            this.confirmBoxEvokeService.success('Pedido en proceso', 'El pedido se está procesando, en los próximos minutos estará visible en tu Área Privada', 'Continuar').subscribe(
              (data) => {
                this.updateCartLines();
                let cartIcon = document.getElementById('cart-icon')
                if (this.cartItems.length == 0) {
                  cartIcon.style.visibility = 'hidden';
                }
                this.router.navigate(['/profile']);
              }
            );
          },
          (error) => {
            console.log(error)
          }
        )
      },
      (error) => {
        console.log(error)
      }
    )
  }

  selectAddress(event) {
    this.idAddressCart = event;

    this.profileService.getAddressId(event).subscribe(data => {
      this.cardAddress.company = data.company;
      this.cardAddress.name = data.first_name + ' ' + data.last_name;
      this.cardAddress.adress = data.address1 + ' ' + data.address2;
      this.cardAddress.city = data.city + ' (' + data.state + ') | ' + data.postCode;
      this.cardAddress.country = data.country;

      this.setPortes(String(data.postCode).slice(0, 2));
    })
  }

  removeItem(id) {
    this.confirmBoxEvokeService.danger('Atención', '¿Seguro que quieres eliminar el artículo del pedido?', 'Eliminar', 'Cancelar')
      .subscribe(resp => {
        if (resp.success === true) {
          this.cartService.removeLine(id).subscribe(data => {
            this.updateCartLines();
            this.cartService.getCart().subscribe(data => {
              if (data) {
                let cartIcon = document.getElementById('cart-icon')
                if (data.lineas.length == 0) {
                  cartIcon.style.visibility = 'hidden';
                } else {
                  cartIcon.innerHTML = data.lineas.length;

                }
              }
            })
          }, (error) => {
            console.log(error);
          });
        }

      });
  }

  updateCartLines() {
    this.loadCart = true;
    this.spinner.show('cartSpinner', {
      size: "large",
      showSpinner: true,
      fullScreen: false,
      bdColor: "rgba(0, 0, 0, 0)",
    });

    this.cartItems = [];
    this.totalPriceItems = 0.0;

    this.authenticationService.getUsersMe().subscribe(
      data => {
        const vat_table = this.cartService.getVatValue();
        vat_table.forEach(
          vat => {
            if (vat[0] == data.VATTypeCode) {
              if (data.VATTypeCode == 41) {
                this.vatPercent = 21;
                this.vatPercent2 = vat[2].replace(',', '.');
              } else {
                this.vatPercent = vat[2].replace(',', '.');
                this.vatPercent2 = null;
              }
            }
          }
        )

        this.cartService.getCart().subscribe(data => {
          if (data.id === null || data.lineas.length === 0) {
            this.emptyCart = false;
            this.spinner.hide();
            this.loadCart = false;

            return;
          }

          this.emptyCart = true;

          const total_lines = Object.keys(data.lineas).length;
          let current_line = 0;
          Object.keys(data.lineas).forEach(key => {
            // this.cartService.getArticleSKU(data.lineas[key].cod_producto).subscribe((article) => {
            // this.cartService.getPriceSKU({ "sku": data.lineas[key].cod_producto, "cantidad": data.lineas[key].cantidad }).subscribe((price_data) => {
            //   if (data.lineas[key].articulo.Euro100 !== null) {
            //     price_data.Price = price_data.Price / 100;
            //   }

            // data.lineas[key].price = price_data;
            if (data.lineas[key].articulo.idSubfamily) {
              data.lineas[key].image = this.imgPath + 'productos/' + data.lineas[key].articulo.idSubfamily + '.jpg';
            }

            this.totalPriceItems = this.totalPriceItems + (data.lineas[key].articulo.price * data.lineas[key].cantidad);
            this.cartItems.push(data.lineas[key]);
            current_line++;
            if (total_lines === current_line) {
              this.spinner.hide();
              this.loadCart = false;
              // console.log(this.cartItems);

            }
            // })
            // },
            //   (error) => {
            //     this.spinner.hide();
            //     this.loadCart = false
            //   })
          });
        }, (error) => {
          this.spinner.hide();
          this.loadCart = false;
        });
      },
      error => {
        this.spinner.hide();
        this.loadCart = false;
      }
    )
  }

  editItem(id_cart, cod_product, cantidad) {
    const article = { 'codigo': cod_product, 'EnvaseA': null, 'EnvaseB': null, 'quantity': 1, 'title': 'Nueva cantidad - ' + cod_product, 'button': 'Cambiar', 'section': 'cart', 'Euro100': null, 'EuroKit': null, 'EuroUnidad': null }

    this.articlesService.getArticleBySKU(cod_product).subscribe(data => {
      if (data?.EnvaseA != null) {
        article.EnvaseA = data.EnvaseA;
      }

      if (data?.EnvaseB != null) {
        article.EnvaseB = data.EnvaseB;
      }

      if (data?.Euro100 != null) {
        article.Euro100 = data.Euro100;
      }
      if (data?.EuroKit != null) {
        article.EuroKit = data.EuroKit;
      }
      if (data?.EuroUnidad != null) {
        article.EuroUnidad = data.EuroUnidad;
      }


      const dialogRef = this.dialog.open(AddToCartComponent, {
        panelClass: 'generic-dialog',
        data: { article: article },
      });

      dialogRef.afterClosed().subscribe(
        (data) => {
          const cursor = document.getElementById('cursor');
          this.cartService.editLine(id_cart, { 'cantidad': data.quantity }).subscribe(
            data => {
              this.updateCartLines();
            }
          )
          //this.toastEvokeService.success('Carrito', data).subscribe();
        }
      );

    })
  }

  setPortes(zip: any) {
    let portes = 0.0;
    if (this.totalPriceItems <= 300 && zip !== '38' && zip !== '35') {
      if (zip === '07') {
        portes = 25.0;
      } else {
        portes = 9.8;
      }
    }
    if (this.totalPriceItems <= 600 && (zip === '38' || zip === '35')) {
      portes = 25.0;
    }
    this.portesPrice = portes;
  }

  formatNumber(floatValue = 1, decimals = 3, multiplier = 1) {
    let floatMultiplied = floatValue * multiplier;
    let stringFloat = floatMultiplied + "";
    let arraySplitFloat = stringFloat.split(".");
    let decimalsValue = "0";
    if (arraySplitFloat.length > 1) {
      decimalsValue = arraySplitFloat[1].slice(0, decimals);
    }
    let integerValue = arraySplitFloat[0];
    let arrayFullStringValue = [integerValue, decimalsValue];
    let FullStringValue = arrayFullStringValue.join(".")
    let floatFullValue = parseFloat(FullStringValue) + "";
    let formatFloatFullValue = new Intl.NumberFormat('de-DE', { minimumFractionDigits: decimals, style: 'currency', currency: 'EUR' }).format(Number(floatFullValue));
    return formatFloatFullValue;
  }
}
