import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastEvokeService } from '@costlydeveloper/ngx-awesome-popup';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit{
  search = new FormControl('');
  constructor(private router:Router, private toastEvokeService:ToastEvokeService) {
 
  }
  ngOnInit(): void {
    let searchInput = document.getElementById('searchInput')
    searchInput.addEventListener("keyup", ({ key }) => {
      if (key === "Enter") {
        // if(this.search.value!='' && this.search.value.length>3){
          this.router.navigate(['product'], { queryParams: {search: this.search.value} });
        // }else{
        //   this.toastEvokeService.danger('Información', 'Escribe más de 3 caracteres para comenzar la búsqueda').subscribe();
        // }
      }
    })
  }
  searchQuery(){
    // if(this.search.value!='' && this.search.value.length>3){
      this.router.navigate(['product'], { queryParams: {search: this.search.value} });
    // }else{
    //   this.toastEvokeService.danger('Información', 'Escribe más de 3 caracteres para comenzar la búsqueda').subscribe();
    // }
  }
}
