<div class="date-picker">
  <mat-form-field>
    <mat-label>Rango de fechas</mat-label>
    <mat-date-range-input [rangePicker]="picker">
      <input matStartDate placeholder="Fecha inicio" (dateChange)="startDataChange($event)">
      <input matEndDate placeholder="Fecha Fin" (dateChange)="endDataChange($event)">
    </mat-date-range-input>
    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</div>

<div *ngIf="!date_complete || invoices.length == 0; else invdata" class="info-picker">
  Hay que seleccionar un rango de fechas para mostrar las facturas
</div>

<div *ngIf="download">
  <ngx-spinner name="cartSpinner" color="#82bb27" type="ball-scale-multiple">Descargando...</ngx-spinner>
</div>

<ng-template #invdata>
  <div *ngFor="let invoice of invoices; index as i;" class="user-data-card">
    <div class="profile-info">
        <div class="row">
          <div class="col-md-8">
            <p class="profile-title">Información de la factura</p>
          </div>
          <div class="col-md-4 icon-adrress-row">
            <fa-icon  (click)="downloadPDF(invoice.IDInvoice, invoice.CodInvoice)" style="cursor: pointer; font-size: 30px;" class="icon-address-edit" [icon]="faDownload"></fa-icon>
          </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-4">
                        <p class="info-label bold-class">Código de factura</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data bold-class">{{invoice.CodInvoice}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="row">
                    <div class="col-4">
                        <p class="info-label">Fecha</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data">{{getDateFormat(invoice.InvoiceDate) | date: 'dd/MM/YYYY'}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-4">
                        <p class="info-label">Cliente</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data">{{invoice.Description}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="row">
                    <div class="col-4">
                        <p class="info-label"></p>
                    </div>
                    <div class="col-8">
                        <p class="info-data"></p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-4">
                        <p class="info-label bold-class">Importe total</p>
                    </div>
                    <div class="col-8">
                        <p class="info-data bold-class">{{invoice.TotalInvoice}} €</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</ng-template>
