<div class="hero-wrapper" style="max-height: 600px;">
  <swiper class="hero-swiper" style="--swiper-navigation-color: #fff;" [slidesPerView]="1" [spaceBetween]="0"
    [navigation]="true" [loop]="true">
    <!-- <ng-template swiperSlide>
            <div slot="container-start" class="swiper-bg swiper-slider">
                <div class="swiper-container">
                    <div class="slide-wrapper" [class.centerSlide]="center">
                        <img class="slide-img" src="../../../assets/images/home/slide/hero_slide.png" alt="">
                    </div>
                </div>
            </div>
        </ng-template> -->
    <ng-template *ngFor="let slide of slideNumbers" swiperSlide>
      <div slot="container-start" class="swiper-bg swiper-slider">
        <div class="swiper-container">
          <div class="slide-wrapper" [class.centerSlide]="center">
            <img class="slide-img" [src]='imgURL + (slide) + ".jpg"' alt="">
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>