<div class="container">
  <div class="content-dialog">
    <h1>Crear dirección</h1>
    <form class="example-form" [formGroup]="address">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100">
            <mat-label>Alias</mat-label>
            <input type="text" matInput formControlName="alias">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="first_name">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Apellido</mat-label>
            <input type="text" matInput formControlName="last_name">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100">
            <mat-label>Compañía</mat-label>
            <input type="text" matInput formControlName="company">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100">
            <mat-label>Dirección 1</mat-label>
            <input type="text" matInput formControlName="address1">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field class="w-100">
            <mat-label>Dirección 2</mat-label>
            <input type="text" matInput formControlName="address2">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Ciudad</mat-label>
            <input type="text" matInput formControlName="city">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Comunidad autónoma</mat-label>
            <input type="text" matInput formControlName="state">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>Codigo postal</mat-label>
            <input type="text" matInput formControlName="postCode">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field>
            <mat-label>País</mat-label>
            <input type="text" placeholder="Seleccionar país" aria-label="País" matInput
              [formControl]="address.get('country')" [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let country of filteredOptions | async" [value]="country[0]">
                {{country[0]}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="button-wrapper">
      <div (click)="actionAddress()" class="btn-secondary w-100 button-address">Guardar</div>
    </div>
  </div>
</div>