import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  urlsNotUse: Array<string>;
  constructor(
    private router: Router
  ) {
    this.urlsNotUse = [
      '/api/productLine',
      '/api/productFamilyByLine',
      '/api/productSubFamily',
      '/api/articlesbySubfamily'
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isValidRequestForInterceptor(request.url)) {
      const jwt_token = localStorage.getItem('jwt_token');
      if (jwt_token) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt_token}` //Authorization
          }
        });
      }
    }

    return next.handle(request).pipe(tap(
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
          if (err.status === 401) {
            this.router.navigate(['/']);
          }
        }
      }
    ));

  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    let positionIndicator: string = '/';
    let position = requestUrl.indexOf(positionIndicator);
    if (position > 0) {
      let destination: string = requestUrl.substring(position + positionIndicator.length);
      for (let address of this.urlsNotUse) {
        if (new RegExp(address).test(destination)) {
          return false;
        }
      }
    }
    return true;
  }
}
