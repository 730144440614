import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '../app-settings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductFamilyService {

  constructor(private http: HttpClient) { }

  public reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  });

  getProductFamily(line:string) {
    return this.http.get<any>(
      `${AppSettings.API_URI}/productFamilyByLine?lineID=`+line, { headers: this.reqHeader }
    ).pipe(
      map(data => {
        return data;
      }, error => {
        return false;
      })
    )
  }
}
