import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-product-family-card',
  templateUrl: './product-family-card.component.html',
  styleUrls: ['./product-family-card.component.scss']
})
export class ProductFamilyCardComponent {


  @Input() menuItem: any;
  constructor() {
    this.isLogged();
  }

  isLogged() {
    if (localStorage.getItem('jwt_token') != null) {
      return true;
    }

    return false;
  }
}
