<div class="content-wrapper container product-lines">
  <div class="product-lines-wrapper">
    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 col-product-line" (click)="getProductLine(menuItem)"
        *ngFor="let menuItem of menuItemsArray">
        <app-product-family-card [menuItem]=menuItem>
        </app-product-family-card>
      </div>
      <div style="height: 1096px;" *ngIf="menuItemsArray.length===0"></div>
    </div>
  </div>
</div>