<div class="login-wrapper">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-6">
        <form [formGroup]="loginForm" (submit)="login()" enctype="application/x-www-form-urlencoded" class="form-login">
          <div class="login-title">
            <p>Iniciar sesión</p>
          </div>
          <div class="form-login-content">
            <div class="input-wrapper">
              <input id="username" matInput placeholder="Usuario" formControlName="username">
            </div>
            <div class="input-wrapper">
              <input id="password" [type]="showingPassword===true ? 'text' : 'password'" matInput
                placeholder="Contraseña" formControlName="password">
              <i style="cursor: pointer;"
                [ngClass]="showingPassword===true ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
                (click)="showPassword()"></i>
            </div>
            <div class="error-msg">{{message}}</div>
            <div class="btn-login-wrapper">
              <button class="btn-primary btn-login" type="submit" name="button" (click)="login()">Iniciar
                sesión</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-6 justify-content-center d-flex">
        <img class="rotate-screws" src="../../../assets/images/home/svg/screw_decorations.svg"
          alt="Tornillos decorativos">
      </div>
    </div>
  </div>
</div>